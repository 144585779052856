import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    TextInput,
} from 'react-native';
import {
    c_light_grey,
    c_primary_red,
    c_white,
    c_primary_black
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Button from '../../components/Button';
import { updateShippingAddress, addShippingAddress, deleteShippingAddress } from '../../store/actions';
import PostCodeModal from '../../modals/PostCodeModal';
import { twoAlert } from '../../utils';

function AddShippingAddress({
    navigation,
    route,
    updateShippingAddress,
    addShippingAddress,
    deleteShippingAddress
}) {

    const { type, data, user } = route.params;
    const [visibleModal, setVisibleModal] = useState(false);
    const [receiver, setReceiver] = useState(data ? data.receiver : '');
    const [phoneNum, setPhoneNum] = useState(data ? data.phoneNum : '');
    const [postCode, setPostCode] = useState(data ? data.post_code : '');
    const [address1, setAddress1] = useState(data ? data.address1 : '');
    const [address2, setAddress2] = useState(data ? data.address2 : '');

    useEffect(() => {
        navigation.setOptions({
            title: type ? '배송지 추가' : '배송지 수정',
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);

    const onSave = async () => {
        const req = {
            receiver,
            phoneNum,
            post_code: postCode,
            address1,
            address2,
            user_id: user._id
        };
        if (type) {
        } else {
            const res = await updateShippingAddress(req, data._id);
            if (res) {
                alert('이미 등록된 배송지입니다.');
            } else {
                navigation.goBack();
            }
        }
    }

    const onAdd = async () => {
        const req = {
            receiver,
            phoneNum,
            post_code: postCode,
            address1,
            address2,
        };
        const res = await addShippingAddress(req, user._id);
        if (res) {
            alert('이미 등록된 배송지입니다.');
        } else {
            navigation.goBack();
        }
    }

    const onDelete = async () => {
        twoAlert('배송지 정보를 삭제하시겠습니까?', async () => {
            const res = await deleteShippingAddress(user._id, data._id);
            if (res) {
                alert('삭제실패');
            } else {
                navigation.goBack();
            }
        });
    }

    const onSearchAddress = () => {
        setVisibleModal(true);
    }

    return (
        <View style={globalStyle.flex}>
            <KeyboardAwareScrollView style={styles.mainContainer}>
                <View>
                    {/** 수령인 */}
                    <View style={{ marginBottom: perfectSize(15) }}>
                        <Text style={[globalStyle.greySmText, { marginBottom: perfectSize(5) }]}>
                            {'수령인'}
                        </Text>
                        <TextInput
                            value={receiver}
                            onChangeText={text => setReceiver(text)}
                            placeholder={'수령인을 입력하세요'}
                            style={styles.textInput}
                        />
                    </View>

                    {/** 전화번호 */}
                    <View style={{ marginBottom: perfectSize(15) }}>
                        <Text style={[globalStyle.greySmText, { marginBottom: perfectSize(5) }]}>
                            {'전화번호'}
                        </Text>
                        <TextInput
                            value={phoneNum}
                            onChangeText={text => setPhoneNum(text)}
                            placeholder={'‘-’를 제외한 전화번호를 입력하세요'}
                            style={styles.textInput}
                        />
                    </View>

                    {/** 주소 */}
                    <View style={{ marginBottom: perfectSize(15) }}>
                        <Text style={[globalStyle.greySmText, { marginBottom: perfectSize(5) }]}>
                            {'주소'}
                        </Text>
                        <View style={globalStyle.flexRow}>
                            <TextInput
                                value={postCode}
                                onChangeText={text => setPostCode(text)}
                                placeholder={'우편번호'}
                                style={styles.textInput}
                                editable={false}
                            />
                            <TouchableOpacity
                                onPress={onSearchAddress}
                                style={styles.button}>
                                <Text style={styles.buttonLabel}>
                                    {'주소찾기'}
                                </Text>
                            </TouchableOpacity>
                        </View>
                        <TextInput
                            value={address1}
                            onChangeText={text => setAddress1(text)}
                            placeholder={'주소'}
                            style={[styles.textInput, { marginVertical: perfectSize(10) }]}
                            editable={false}
                        />
                        <TextInput
                            value={address2}
                            onChangeText={text => setAddress2(text)}
                            placeholder={'상세주소'}
                            style={[styles.textInput, { marginVertical: perfectSize(10) }]}
                        // editable={false}
                        />
                    </View>
                </View>
            </KeyboardAwareScrollView>
            <View style={globalStyle.twoBottomButton}>
                {
                    type ?
                        <View style={globalStyle.flex}>
                            <Button
                                onPress={onAdd}
                                textColor={c_white}
                                label={'추가'}
                                color={c_primary_red}
                                isBorder={false}
                                disable={!(receiver && phoneNum && postCode && address1 && address2)}
                            />
                        </View>
                        :
                        <>
                            <View style={{ flex: 1, marginRight: perfectSize(5) }}>
                                <Button
                                    onPress={onDelete}
                                    textColor={c_primary_red}
                                    label={'삭제'}
                                    color={c_white}
                                    isBorder={true}
                                    borderColor={c_primary_red}
                                />
                            </View>
                            <View style={{ flex: 1, marginLeft: perfectSize(5) }}>
                                <Button
                                    onPress={onSave}
                                    textColor={c_white}
                                    label={'저장'}
                                    color={c_primary_red}
                                    isBorder={false}
                                />
                            </View>
                        </>
                }
            </View>
            <PostCodeModal
                isVisible={visibleModal}
                hideModal={() => setVisibleModal(false)}
                onSelect={address => {
                    setPostCode(address.zonecode);
                    setAddress1(address.roadAddress);
                }}
            />
        </View>
    )
}

const mapStateTopProps = state => ({

});

export default connect(mapStateTopProps, { updateShippingAddress, addShippingAddress, deleteShippingAddress })(AddShippingAddress);

const styles = {
    mainContainer: {
        flex: 1,
        backgroundColor: c_white,
        paddingHorizontal: perfectSize(15),
        paddingVertical: perfectSize(30)
    },
    textInput: {
        fontSize: perfectSize(14),
        letterSpacing: -0.15,
        color: c_primary_black,
        flex: 1,
        borderWidth: 1,
        borderColor: c_light_grey,
        borderRadius: perfectSize(6),
        paddingHorizontal: perfectSize(15),
        padding: perfectSize(16),
        marginRight: perfectSize(5),
    },
    button: {
        width: perfectSize(70),
        height: perfectSize(46),
        borderRadius: perfectSize(6),
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#555555'
    },
    buttonLabel: {
        fontWeight: 'bold',
        fontSize: perfectSize(13),
        color: c_white
    },
}