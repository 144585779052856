import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    TextInput,
} from 'react-native';
import {
    c_light_grey,
    c_primary_red,
    c_second_white,
    c_white,
} from '../../variables/colors';
import CustomTextInput from '../../components/CustomTextInput';
import Separator from '../../components/Separator';
import BorderButton from '../../components/BorderButton';
import Button from '../../components/Button';
import { globalStyle, perfectSize } from '../../variables/styles';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { oneAlert, validateEmail, validateStrongPassword } from '../../utils';
import { updatePersonalInfo, updatePassword, logoutMember } from '../../store/actions';
import { useIsFocused } from '@react-navigation/native';

function Profile({ navigation, user, updatePersonalInfo, logoutMember }) {

    const [nickName, setNickName] = useState('');
    const [editableNickName, setEditableNickName] = useState(false);
    const [email, setEmail] = useState('');
    const [editableEmail, setEditableEmail] = useState(false);
    const [password, setPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [equalErr, setEqualErr] = useState(false);
    const [strongErr, setStrongErr] = useState(false);
    const [oldPassErr, setOldPassError] = useState(false);
    const [memberLoading, setMemberLoading] = useState(false);

    // const ref_nickName = useRef(null);
    // const ref_email = useRef(null);
    const isFocusedHistory = useIsFocused();

    useEffect(() => {
        setNickName(user.nickname);
        setEmail(user.email);
        setEditableNickName(true);
        setEditableEmail(true);
        // ref_nickName.current.focus();
    }, [isFocusedHistory]);

    useEffect(() => {
        navigation.setOptions({
            title: "회원정보관리",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => navigation.openDrawer()}>
                        <Image
                            source={require('../../../assets/images/ic_menu_sub.png')}
                            style={globalStyle.headerNormalIcon}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);

    const onEditNickName = () => {
        if (editableNickName) {
            if (!nickName) {
                oneAlert('닉네임을 입력해주세요.');
                return;
            }
            setEditableNickName(false);
            updatePersonalInfo(0, nickName.trim(), user._id);
        } else {
            setEditableNickName(true);
        }
    }

    const onEditEmail = () => {
        if (editableEmail) {
            if (validateEmail(email)) {
                setEditableEmail(false);
                updatePersonalInfo(1, email.trim(), user._id);
            } else {
                alert('유효하지않은 이메일입니다.')
            }
        } else {
            setEditableEmail(true);
        }
    }

    const onUpdatePassword = async () => {
        setOldPassError(false);
        const { userKey } = user;

        if (password !== confirmPassword) {
            setEqualErr(true);
            return;
        }

        if (!validateStrongPassword(password)) {
            setStrongErr(true);
            return;
        }
        setLoading(true);
        const res = await updatePassword({ userkey: userKey, newpasswd: password, oldpasswd: oldPassword });
        if (res) {
            setOldPassError(true);
        } else {
            setPassword('');
            setOldPassword('');
            setConfirmPassword('');
            alert('비밀번호 변경 성공');
        }
        setLoading(false);
    }

    const onMemberOut = async () => {
        setMemberLoading(true);
        await logoutMember(user._id);
        navigation.navigate('Home');
        oneAlert('회원탈퇴가 완료되었습니다.');
        setMemberLoading(false);
    }

    return (
        <View style={globalStyle.flex}>
            <KeyboardAwareScrollView style={styles.mainContainer}>
                <View>
                    {/** 회원 정보 */}
                    <View style={styles.section}>
                        <Text style={[globalStyle.regularBoldText, { marginBottom: perfectSize(15) }]}>
                            {'회원 정보'}
                        </Text>

                        {/** 아이디 */}
                        <View style={{ marginBottom: perfectSize(15) }}>
                            <Text style={[globalStyle.greySmText, { marginBottom: perfectSize(5) }]}>
                                {'아이디'}
                            </Text>
                            <Text style={globalStyle.normalBoldText}>
                                {user.decPhoneNum}
                            </Text>
                        </View>

                        {/** 닉네임 */}
                        <View style={{ marginBottom: perfectSize(15) }}>
                            <Text style={[globalStyle.greySmText, { marginBottom: perfectSize(5) }]}>
                                {'닉네임'}
                            </Text>
                            <View style={globalStyle.flexRow}>
                                <TextInput
                                    // ref={ref_nickName}
                                    placeholder={'닉네임'}
                                    value={nickName.trim() || ''}
                                    onChangeText={text => setNickName(text)}
                                    editable={editableNickName}
                                    style={[globalStyle.regularText, styles.textInput, { borderColor: editableNickName ? c_primary_red : c_light_grey }]}
                                />
                                <TouchableOpacity
                                    onPress={onEditNickName}
                                    style={[styles.button, { backgroundColor: editableNickName ? c_primary_red : '#555555' }]}>
                                    <Text style={styles.buttonLabel}>
                                        {editableNickName ? '저장' : '수정'}
                                    </Text>
                                </TouchableOpacity>
                            </View>
                        </View>

                        {/** 이메일 */}
                        <View style={{ marginBottom: perfectSize(15) }}>
                            <Text style={[globalStyle.greySmText, { marginBottom: perfectSize(5) }]}>
                                {'이메일'}
                            </Text>
                            <View style={globalStyle.flexRow}>
                                <TextInput
                                    // ref={ref_email}
                                    placeholder={'이메일'}
                                    value={email || ''}
                                    onChangeText={text => setEmail(text)}
                                    editable={editableEmail}
                                    style={[globalStyle.regularText, styles.textInput, { borderColor: editableEmail ? c_primary_red : c_light_grey }]}
                                />
                                <TouchableOpacity
                                    onPress={onEditEmail}
                                    style={[styles.button, { backgroundColor: editableEmail ? c_primary_red : '#555555' }]}>
                                    <Text style={styles.buttonLabel}>
                                        {editableEmail ? '저장' : '수정'}
                                    </Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                    <Separator
                        height={perfectSize(8)}
                        color={c_second_white}
                    />

                    {/** 배송지관리 */}
                    <View style={styles.section}>
                        <Text style={[globalStyle.regularBoldText, { marginBottom: perfectSize(15) }]}>
                            {'배송지관리'}
                        </Text>
                        <BorderButton
                            onPress={() => navigation.navigate('ShippingAddress')}
                            color={c_primary_red}
                            label={'배송지 관리'}
                            buttonStyle={{ marginTop: perfectSize(5), marginBottom: perfectSize(10) }}
                        />
                    </View>
                    <Separator
                        height={perfectSize(8)}
                        color={c_second_white}
                    />

                    {/** 비밀번호 변경 */}
                    <View style={styles.section}>
                        <Text style={[globalStyle.regularBoldText, { marginBottom: perfectSize(15) }]}>
                            {'비밀번호 변경'}
                        </Text>

                        <CustomTextInput
                            warningText={'비밀번호가 일치하지 않습니다'}
                            isWarn={oldPassErr}
                            placeholder={'현재 비밀번호를 입력하세요.'}
                            value={oldPassword || ''}
                            onChangeText={text => {
                                setEqualErr(false);
                                setStrongErr(false);
                                setOldPassError(false);
                                setOldPassword(text);
                            }}
                            style={{ marginBottom: perfectSize(10) }}
                            isSecure={true}
                        />

                        <CustomTextInput
                            warningText={'영문+숫자+특수기호 조합 8~15자'}
                            isWarn={strongErr}
                            placeholder={'비밀번호 입력'}
                            value={password || ''}
                            onChangeText={text => {
                                setEqualErr(false);
                                setStrongErr(false);
                                setOldPassError(false);
                                setPassword(text);
                            }}
                            style={{ marginBottom: perfectSize(10) }}
                            isSecure={true}
                        />

                        <CustomTextInput
                            warningText={'비밀번호가 일치하지 않습니다'}
                            isWarn={equalErr}
                            placeholder={'새 비밀번호를 한 번 더 입력하세요.'}
                            value={confirmPassword || ''}
                            onChangeText={text => {
                                setEqualErr(false);
                                setStrongErr(false);
                                setOldPassError(false);
                                setConfirmPassword(text);
                            }}
                            style={{ marginBottom: perfectSize(10) }}
                            isSecure={true}
                        />
                        {
                            !strongErr &&
                            <Text style={[globalStyle.smText, styles.bottomText]}>
                                {'영문+숫자+특수기호 조합 8~15자'}
                            </Text>
                        }
                        <View
                            style={{ marginBottom: perfectSize(10), marginTop: perfectSize(20) }}>
                            <Button
                                onPress={onUpdatePassword}
                                textColor={c_primary_red}
                                label={'비밀번호 변경'}
                                color={c_white}
                                loading={loading}
                                borderColor={c_primary_red}
                                isBorder={true}
                            />
                        </View>
                    </View>
                    <Separator
                        height={perfectSize(8)}
                        color={c_second_white}
                    />

                    <View style={styles.section}>
                        <Button
                            // onPress={onMemberOut}
                            onPress={() => navigation.navigate('LeaveMember')}
                            textColor={c_primary_red}
                            label={'회원탈퇴'}
                            color={c_white}
                            // loading={memberLoading}
                            borderColor={c_primary_red}
                            isBorder={true}
                        />
                    </View>
                </View>
            </KeyboardAwareScrollView>
        </View>
    )
}

const mapStateTopProps = state => ({
    user: state.auth.user
});

export default connect(mapStateTopProps, { updatePersonalInfo, logoutMember })(Profile);

const styles = {
    mainContainer: {
        flex: 1,
        backgroundColor: c_white,
    },
    imageBackground: {
        flex: 1,
        padding: perfectSize(15),
        paddingTop: perfectSize(20),
    },
    section: {
        paddingVertical: perfectSize(20),
        paddingHorizontal: perfectSize(15)
    },
    textInput: {
        flex: 1,
        borderWidth: 1,
        borderColor: c_light_grey,
        borderRadius: perfectSize(6),
        paddingHorizontal: perfectSize(15),
        height: perfectSize(46),
        marginRight: perfectSize(5),
    },
    button: {
        width: perfectSize(70),
        height: perfectSize(46),
        borderRadius: perfectSize(6),
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#555555'
    },
    buttonLabel: {
        fontWeight: 'bold',
        fontSize: perfectSize(13),
        color: c_white
    },
    closeIcon: {
        width: perfectSize(11),
        height: perfectSize(11),
        resizeMode: 'contain',
    },
    price: {
        fontSize: perfectSize(16),
        fontWeight: 'bold',
        color: c_primary_red,
        letterSpacing: -0.16,
        marginRight: perfectSize(10)
    },
}