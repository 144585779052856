export function getDesignDimension() {
  const designResolution = {
    width: 360,
    height: 841
  }
  return designResolution;
}

// export const HOST = 'http://1.201.162.11:3001'; // Test
export const HOST = 'https://www.tickettong.net'; // Production
// export const HOST = 'http://localhost:3000'; // local

export const UPLOAD_URL = `${HOST}/uploads/`;

export const LOGIN = 'LOGIN';
export const REGISTER = 'REGISTER';
export const LOGOUT = 'LOGOUT';
export const GET_BANNER = 'GET_BANNER';
export const GET_HASH_TAG = 'GET_HASH_TAG';
export const GET_SEARCH_WORD = 'GET_SEARCH_WORD';
export const GET_CATEGORY = 'GET_CATEGORY';
export const GET_COMMUNITY = 'GET_COMMUNITY';
export const GET_PERFORMANCE_LIST = 'GET_PERFORMANCE_LIST';
export const GET_REVIEW = 'GET_REVIEW';
export const ADD_REVIEW = 'ADD_REVIEW';
export const DELETE_REVIEW = 'DELETE_REVIEW';
export const ADD_SEAT = 'ADD_SEAT';
export const DELET_SEAT = 'DELET_SEAT';
export const RESET_SEAT = 'RESET_SEAT';
export const SET_TOTAL_PRICE = 'SET_TOTAL_PRICE';
export const SET_ORDER_DETAIL = 'SET_ORDER_DETAIL';
export const ADD_COUPON = 'ADD_COUPON';
export const GET_COUPONS = 'GET_COUPONS';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_SHIPPING_ADDRESS = 'UPDATE_SHIPPING_ADDRESS';
export const ADD_SHIPPING_ADDRESS = 'ADD_SHIPPING_ADDRESS';
export const DELETE_SHIPPING_ADDRESS = 'DELETE_SHIPPING_ADDRESS';
export const SET_DEFAULT_ADDRESS = 'SET_DEFAULT_ADDRESS';
export const GET_NOTICE = 'GET_NOTICE';
export const ADD_EXPECTATION = 'ADD_EXPECTATION';
export const GET_EXPECTATION = 'GET_EXPECTATION';
export const DELETE_EXPECTATION = 'DELETE_EXPECTATION';
export const SET_NOTIFICATION_COUNT = 'SET_NOTIFICATION_COUNT';
export const UPDATE_SEAT_OFFSET = 'UPDATE_SEAT_OFFSET';
export const UPDATE_USER_WALLET = 'UPDATE_USER_WALLET';
export const UPDATE_USER_SUBSCRIBE = "UPDATE_USER_SUBSCRIBE";


export const INVITATION_LOAD_START = 'INVITATION_LOAD_START';
export const INVITATION_LOAD_SUCCESS = 'INVITATION_LOAD_SUCCESS';
export const INVITATION_LOAD_FAIL = 'INVITATION_LOAD_FAIL';
export const INVITATION_PAGE_CHANGE = 'INVITATION_PAGE_CHANGE';
export const INVITATION_LIST_RESET = 'INVITATION_LIST_RESET';
export const INVITATION_LIST_UPDATE = 'INVITATION_LIST_UPDATE';

export const COUPON_LOAD_START = 'COUPON_LOAD_START';
export const COUPON_LOAD_SUCCESS = 'COUPON_LOAD_SUCCESS';
export const COUPON_LOAD_FAIL = 'COUPON_LOAD_FAIL';
export const COUPON_PAGE_CHANGE = 'COUPON_PAGE_CHANGE';
export const COUPON_LIST_RESET = 'COUPON_LIST_RESET';
export const COUPON_LIST_UPDATE = 'COUPON_LIST_UPDATE';
export const COUPON_ADD_START = 'COUPON_ADD_START';
export const COUPON_ADD_FAIL = 'COUPON_ADD_FAIL';
export const COUPON_ADD_SUCCESS = 'COUPON_ADD_SUCCESS';
export const COUPON_WARN_RESET = 'COUPON_WARN_RESET';


export const PERFORMANCE_LOAD_START = 'PERFORMANCE_LOAD_START';
export const PERFORMANCE_LOAD_SUCCESS = 'PERFORMANCE_LOAD_SUCCESS';
export const PERFORMANCE_LOAD_FAIL = 'PERFORMANCE_LOAD_FAIL';
export const PERFORMANCE_LIST_RESET = 'PERFORMANCE_LIST_RESET';
export const PERFORMANCE_LIST_UPDATE = 'PERFORMANCE_LIST_UPDATE';
export const PERFORMANCE_PAGE_CHANGE = 'PERFORMANCE_PAGE_CHANGE';


export const COIN_LOAD_START = 'COIN_LOAD_START';
export const COIN_LOAD_FAIL = 'COIN_LOAD_FAIL';
export const TTMI_COIN_LOAD_SUCCESS = 'TTMI_COIN_LOAD_SUCCESS';
export const TTMI_COIN_LIST_RESET = 'TTMI_COIN_LIST_RESET';
export const TTM_COIN_LOAD_SUCCESS = 'TTM_COIN_LOAD_SUCCESS';
export const TTM_COIN_LIST_RESET = 'TTM_COIN_LIST_RESET';


export const ALARM_LOAD_START = 'ALARM_LOAD_START';
export const ALARM_LOAD_SUCCESS = 'ALARM_LOAD_SUCCESS';
export const ALARM_LOAD_FAIL = 'ALARM_LOAD_FAIL';
export const ALARM_LIST_RESET = 'ALARM_LIST_RESET';
export const ALARM_LIST_UPDATE = 'ALARM_LIST_UPDATE';
export const ALARM_LIST_UNREAD = 'ALARM_LIST_UNREAD';


export const ORDER_LOAD_START = 'ORDER_LOAD_START';
export const ORDER_LOAD_SUCCESS = 'ORDER_LOAD_SUCCESS';
export const ORDER_LOAD_FAIL = 'ORDER_LOAD_FAIL';
export const ORDER_LIST_RESET = 'ORDER_LIST_RESET';
export const ORDER_PAGE_CHANGE = 'ORDER_PAGE_CHANGE';



export const APPICON_LOAD_SUCCESS = 'APPICON_LOAD_SUCCESS';
export const APPICON_LIST_RESET = 'APPICON_LIST_RESET';

export const UPDATE_COIN_PRICES = 'UPDATE_COIN_PRICES';

export const TOMATOPAY_MID = 'ttticket1m';

export const TOMATOPAY_LICENSE = '0jT9ZwErlljRQoErUh1dDYYBJs6HXbgpBeqZ4OWAQXq0Taf6qXcQDPfFaigcnmGSrLpCq4QKOlTZ4UECMTyggQ==';
export const TOMATOPAY_RETURN_URL = `${HOST}/api/tongtongcoin/returnTomatoPay`

export const CULTURE_MID = 'ttticket2m';
export const CULTURE_LICENSE = 'ykxKK6q1aFRygsLStHVR4lUvWlRqHGYgcvHnaBdaF+iqVU7JALMLAdzXFoj5r23HVaJgvpv6DtmXb7fS+K/huA==';
