import _ from 'lodash';
import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    ScrollView,
    ImageBackground,
    TextInput,
    Linking,
} from 'react-native';
import {
    c_light_grey,
    c_second_grey,
    c_third_grey,
    c_primary_red,
    c_second_white,
    c_dark_white,
    c_white,
    c_grey,
    c_black,
    c_primary_black,
    c_primary_blue, c_third_black, c_fourth_white, c_thrid_white, c_dark_red, c_fourth_grey
} from '../../../variables/colors';
import {HOST, UPLOAD_URL} from '../../../variables/constants';
import {globalStyle, perfectSize} from '../../../variables/styles';
import RadioButton from '../../../components/RadioButton';
import Separator from '../../../components/Separator';
import Button from '../../../components/Button';
import CheckBox from '../../../components/CheckBox';
import {getData, numberWithCommas, oneAlert, twoAlert} from '../../../utils';
import {
    setOrderDetail,
    order,
    termsOfService,
    getUsableCoinList,
    getRewardPoint,
    getUserCardList, requestPayCard, DelCardInfo
} from '../../../store/actions';
import Collapsible from 'react-native-collapsible';
import moment from 'moment';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import CardModal from '../../../modals/CardModal';
import Entypo from "@expo/vector-icons/Entypo";
import OneAlertModal from "../../../modals/OneAlertModal";
import Tag from "../../../components/Tag";

const Checkout = ({
                      user,
                      navigation,
                      orderDetail,
                      totalPrice,
                      setOrderDetail,
                      selectedSeat,
                      selectedNum,
                      route
                  }) => {

    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('01012345678');
    const [email, setEmail] = useState('ticket@etomato.com');
    const [paymentType, setPaymentType] = useState(0);
    const [termAll, setTermAll] = useState(false);
    const [term1, setTerm1] = useState(false);
    const [term2, setTerm2] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isCollapsed, setCollapsed] = useState(true);
    const [isNavUp, setNavUp] = useState(false);
    const [deliveryAddress, setDeliveryAddress] = useState();
    const [countTerms, setCountTerms] = useState(0);
    const [phoneNumberGroup, setPhoneNumberGroup] = useState({});
    const [coinData, setCoinData] = useState({});
    const [visibleCardModal, setVisibleCardModal] = useState(false);
    const [visibleAlertModal, setVisibleAlertModal] = useState(false);
    const refScrollView = useRef(null);
    const currentTimeRef = useRef(null);
    const [rewardPoint, setRewardPoint] = useState(0);
    const [rewardPrice, setRewardPrice] = useState(0);
    const [ttcoinPrice, setTTCOINPrice] = useState(0);
    const {performance, roundIndex} = route.params;
    const detailedTime = performance.schedule.detailed_time[roundIndex];

    const [isPriceExtend, setIsPriceExtend] = useState(true);
    const [isSavePriceExtend, setIsSavePriceExtend] = useState(true);
    const [isCardNumber, setIsCardNumber] = useState('');
    const [isExpirationDate, setIsExpirationDate] = useState('');
    const [isChangeDate, setIsChangeDate] = useState('');
    const [isPassword, setIsPassword] = useState('');
    const [isUserIdNumber, setIsUserIdNumber] = useState('');

    const [useAppCard, setUseAppCard] = useState(false);
    const [isSaveCardInfo, setSaveCardInfo] = useState([]);
    const [useSaveInfo, setUseSaveInfo] = useState([]);
    const [isTermsContents, setTermsContents] = useState();

    const [isChoiceCard, setIsChoiceCard] = useState([]);
    const [isChoiceCardInfo, setChoiceCardInfo] = useState(0);
    const [useDifferentCard, setUseDifferentCard] = useState(false);

    const setUseSaveData = (index) => {
        setUseSaveInfo(isSaveCardInfo[index])
    }
    useEffect(() => {
        getInitData();
        getTermsOfService();
        getUserCardData();
    }, []);

    useEffect(() => {
        getRewardData();
    }, [totalPrice]);

    useEffect(() => {
    }, [isChoiceCard]);

    const getInitData = async () => {
        setPhoneNumber(user.decPhoneNum);
        setEmail(user.email);
        const coin = await getUsableCoinList(user.decPhoneNum, user.wallet_name);
        setCoinData(coin)
    }

    const getRewardData = async () => { // user.decPhoneNum 01050556371
        setRewardPrice(totalPrice);
        await getRewardPoint(user._id).then(res => {
            if (res?.Code === "200") {
                setRewardPoint(res?.data?.Point);
                const percentage = 70;
                const result = (totalPrice * percentage) / 100; // 70퍼센트 금액
                if (rewardPoint - result >= 0) { // 포인트가 70퍼센트 금액 이상있는경우
                    setRewardPrice(totalPrice - result);
                } else { // 포인트가 70퍼센트 금액이 아닌경우
                    setRewardPrice(totalPrice - rewardPoint);
                }
            } else {
                setRewardPrice(totalPrice);
            }
        });
    }

    const getUserCardData = async () => {
        await getUserCardList(user._id, user.decPhoneNum, user.name).then(res => {
            if (res.status === 1) {
                setSaveCardInfo(res.data.cardinfo)
            }
        })
    }

    const DelCardData = async (seq) => {
        // await DelCardInfo(user._id, seq, user.decPhoneNum, user.name).then(async res => {
        //     if (res.resultCode === '0000') {
        //         await getUserCardList(user._id, user.decPhoneNum, user.name).then(res => {
        //             setCardSeq(res.cardinfo)
        //             console.log('coldkh', res)
        //         })
        //     }
        // })
    }

    function onCallCard() {
        setVisibleCardModal(true);
        const newDate = new Date().getTime();
        currentTimeRef.current = newDate;
        const moid = "ttticket0m" + newDate;
        const type = "ticket"
        const point = totalPrice - rewardPrice
        onCreateCard(moid, type, point);
    }

    useEffect(() => {
        user.delivery_address &&
        user.delivery_address.map((item, index) => {
            item.default && setDeliveryAddress(item);
        });
    }, [user]);

    useEffect(() => {
        setTermAll(countTerms === 2);
    }, [countTerms]);

    const renderHeader = () => {
        return (
            <View style={styles.headerDate}>
                <View style={globalStyle.flexRow}>
                    <Text style={[
                        globalStyle.smText,
                        {
                            color: c_white,
                            opacity: 0.6
                        }
                    ]}>
                        {'좌석선택'}
                    </Text>
                    <Image
                        source={require('../../../../assets/images/ic_before.png')}
                        style={styles.optionNextIcon}
                    />
                    <Text style={[
                        globalStyle.smText,
                        {
                            color: c_white,
                            opacity: 0.6
                        }
                    ]}>
                        {'가격선택'}
                    </Text>
                    <Image
                        source={require('../../../../assets/images/ic_before.png')}
                        style={styles.optionNextIcon}
                    />
                    <Text style={[
                        globalStyle.normalBoldText,
                        {
                            color: c_white
                        }
                    ]}>
                        {'결제/배송'}
                    </Text>
                </View>
            </View>
        )
    }

    const onSuccess = (TID) => {
        setVisibleCardModal(false);
        onCheckOut(TID);
    };

    const onCreateCard = (moid) => {
        axios.post(`${HOST}/api/tongtongcoin/createCardPay`, {moid})
            .then(res => {
                // onCheckCard(moid);
            })
            .catch(err => {
                oneAlert('카드결제 서버에 접속할수 없습니다. 다시 시도해주세요.');
            })
    }

    const requestCardPayData = async (CardNub, CardDate, UserId, Password, CardSeq) => {
        const newDate = new Date().getTime();
        currentTimeRef.current = newDate;
        const moid = "ttticket2m" + newDate;

        await requestPayCard(user._id, performance.title.name, CardNub, CardDate, UserId, Password, rewardPrice, CardSeq, user.name, moid, 'ticket', totalPrice - rewardPrice).then(res => {
            if (res?.status === 1) {
                onCheckOut(res.data.TID);
            } else {
                oneAlert(`카드 결재에 실패하였습니다.`);
            }
        })
    }

    const getTermsOfService = async () => {
        const res = await termsOfService();
        if (!_.isEmpty(res)) {
            setTermsContents(res);
        }
    }

    const checkPoneNumber = () => {
        let count = 0;
        selectedNum.map((item, index) => {
            const seatData = selectedSeat[index].split(":");
            const name = (seatData[0] !== '0' ? `${seatData[0]}층 ` : '') +
                (seatData[1] !== '0' ? `${seatData[1]}구역 ` : '') +
                (seatData[2] !== '0' ? `${seatData[2]}열 ` : '') +
                `${seatData[3]}번`;
            if (orderDetail.deliveryType === '모바일티켓' && !phoneNumberGroup[name]) {
                oneAlert(`${name}좌석 티켓에 해당한 전화번호를 입력해주세요.`);
                setLoading(false);
                return;
            }
            count++;
        });
        return count === selectedNum.length;
    }

    const renderCheck = () => {

        if (totalPrice && !paymentType) {
            return (
                <View style={{flex: 1, marginRight: perfectSize(5)}}>
                    <Button
                        textColor={c_primary_red}
                        label={'결제방식을 선택해주세요.'}
                        color={c_white}
                        isBorder={true}
                        borderColor={c_primary_red}
                    />
                </View>
            )
        }

        if (countTerms === 2) {
            return (
                <View style={{flex: 1, marginLeft: perfectSize(5)}}>
                    <Button
                        onPress={() => {
                            if (orderDetail.deliveryType === '배송') {
                                oneAlert('배송지를 입력해주세요.');
                                return;
                            }

                            if (checkPoneNumber()) {
                                if (useAppCard) {
                                    if (!email) {
                                        oneAlert('이메일을 입력하세요.');
                                        return;
                                    }
                                    setVisibleCardModal(true);
                                    const newDate = new Date().getTime();
                                    currentTimeRef.current = newDate;
                                    const moid = "ttticket0m" + newDate;
                                    getRewardData();
                                    onCallCard();
                                } else {
                                    if (!useDifferentCard) {
                                        requestCardPayData(useSaveInfo.cardNum, useSaveInfo.cardExpire, null, null, useSaveInfo.seq);
                                    } else {
                                        if (isCardNumber.length < 1) {
                                            oneAlert('카드번호를 확인해주세요.');
                                            return;
                                        }
                                        if (isExpirationDate.length < 1) {
                                            oneAlert('유효기간을 확인해주세요.');
                                            return;
                                        }
                                        if (isUserIdNumber.length < 1) {
                                            oneAlert('주민번호 앞6자리(법인카드는 사업자번호)\n를 확인해주세요.');
                                            return;
                                        }
                                        if (isPassword.length < 1) {
                                            oneAlert('비밀번호를 확인해주세요.');
                                            return;
                                        }
                                        requestCardPayData(isCardNumber, isChangeDate, isUserIdNumber, isPassword, 0);
                                    }
                                    // oneAlert('신용카드공제 결제');
                                }
                            }


                            // if (checkPoneNumber()) {
                            //     if (paymentType === 1) {
                            //         if (!email) {
                            //             oneAlert('이메일을 입력하세요.');
                            //             return;
                            //         }
                            //         setVisibleCardModal(true);
                            //         const newDate = new Date().getTime();
                            //         currentTimeRef.current = newDate;
                            //         const moid = "ttticket0m" + newDate;
                            //         onCreateCard(moid);
                            //     } else {
                            //         onCheckOut();
                            //     }
                            // }
                        }}
                        textColor={c_white}
                        label={'결제하기'}
                        color={c_primary_red}
                        isBorder={false}
                        loading={loading}
                    />
                </View>
            )
        } else {
            return (
                <View style={{flex: 1, marginRight: perfectSize(5)}}>
                    <Button
                        onPress={() => {
                            refScrollView.current.scrollToEnd({animated: true});
                        }}
                        textColor={c_primary_red}
                        label={'이용약관에 동의해주세요.'}
                        color={c_white}
                        isBorder={true}
                        borderColor={c_primary_red}
                    />
                </View>
            )
        }
    }

    const bottomView = () => {
        return (
            <View style={styles.bottomButtons}>
                <View style={globalStyle.twoBottomButton}>
                    {renderCheck()}
                </View>
            </View>
        )
    }

    const onCheckOut = async (TID) => {
        setLoading(true);
        let indexDiscount = 0;
        let invitations = [];

        let _tickets = selectedNum.map((item, index) => {
            const seatData = selectedSeat[index].split(":");
            const name = (seatData[0] !== '0' ? `${seatData[0]}층 ` : '') +
                (seatData[1] !== '0' ? `${seatData[1]}구역 ` : '') +
                (seatData[2] !== '0' ? `${seatData[2]}열 ` : '') +
                `${seatData[3]}번`;
            const numData = item.split('j');
            const discount = orderDetail.ticketDiscounts[seatData[4]][indexDiscount];
            // if (orderDetail.deliveryType === '모바일티켓' && !phoneNumberGroup[name]) {
            //     oneAlert(`${name}좌석 티켓에 해당한 전화번호를 입력해주세요.`);
            //     setLoading(false);
            //     return;
            // }
            indexDiscount += 1;
            if (orderDetail.ticketDiscounts[seatData[4]].length <= indexDiscount)
                indexDiscount = 0;
            if (seatData[4])
                return {
                    seat_name: name,
                    row_in_data: numData[1],
                    col_in_data: numData[0].split('i')[1],
                    seat_grade: seatData[4],
                    discount: discount,
                    price: _.filter(performance.prices.prices, (o) => {
                        return o.grade === seatData[4]
                    })[0].price,
                    isGift: orderDetail.deliveryType === '모바일티켓' ? user.decPhoneNum !== phoneNumberGroup[name] : false,
                    gifted_user: orderDetail.deliveryType === '모바일티켓' ? (phoneNumberGroup[name] || '') : ''
                }
        });
        _tickets = _.sortBy(_tickets, (o) => {
            return o.discount
        });

        orderDetail.invitation && orderDetail.invitation.map((invitation, index) => {
            if (invitation.seat === 'specific') {
                const findIndex = _.findIndex(_tickets, (o) => {
                    return (o.seat_grade === invitation.seat_grade) && (!o.isInvitation)
                });
                if (findIndex > -1) {
                    _tickets[findIndex].isInvitation = true;
                    _tickets[findIndex].isFee = !invitation.fee;
                }
            }
            invitations.push(invitation._id);
        });

        orderDetail.invitation && orderDetail.invitation.map((invitation, index) => {
            if (invitation.seat !== 'specific') {
                const findIndex = _.findIndex(_tickets, (o) => {
                    return !o.isInvitation
                });
                if (findIndex > -1) {
                    _tickets[findIndex].isInvitation = true;
                    _tickets[findIndex].isFee = !invitation.fee;
                }
            }
        });

        const req = {
            total_price: paymentType === 4 ? ttcoinPrice : rewardPrice,
            delivery_method: orderDetail.deliveryType,
            main_price: orderDetail.realPrice,
            discount: orderDetail.realPrice - orderDetail.ticketPrice,
            coupon: orderDetail.couponId,
            coupon_price: orderDetail.couponPrice,
            delivery_fee: orderDetail.deliveryFee,
            delivery_address: deliveryAddress && deliveryAddress._id,
            invitation: invitations,
            invitation_discount: orderDetail.invitationPrice,
            fee: orderDetail.fee,
            payment_method: paymentType === 1 ? "신용카드" : paymentType === 2 ? "TTM" : paymentType === 3 ? "TTMI" : "TTCOIN",
            round: roundIndex,
            product_id: performance._id,
            user_id: user._id,
            seatmap_id: performance.seat_map_data[roundIndex],
            tickets: _tickets,
            orderer_name: user.nickname || user.decPhoneNum,
            orderer_phone: phoneNumber,
            orderer_email: email,
            TID: TID,
            point: paymentType === 4 ? numberWithCommas(((totalPrice / coinData.coin_price) - (ttcoinPrice / coinData.coin_price)).toFixed(1)) : totalPrice - rewardPrice,
        }
        console.log('=====order', req);
        const res = await order(req);
        if (res) {
            oneAlert(JSON.stringify(res));
        } else {
            const data = {
                id: performance._id,
                name: performance.title.name,
                date: performance.schedule.detailed_time[roundIndex].date,
                hour: performance.schedule.detailed_time[roundIndex].hour,
                min: performance.schedule.detailed_time[roundIndex].minute,
                address: performance.location.name,
                delivery_method: orderDetail.deliveryType,
            }
            setLoading(false);
            navigation.navigate('OrderComplete', {data});
        }
        setLoading(false);
    }

    const renderCollapsibleView = () => {
        return (
            <View style={styles.collapsedView}>
                <ImageBackground
                    source={{uri: UPLOAD_URL + performance.poster_link}}
                    blurRadius={4}
                    style={styles.imageBackground}>
                    <View style={styles.blurContainer}/>
                    <View style={globalStyle.flexSpaceBetween}>
                        <View>
                            <Text style={globalStyle.normalWhiteBoldText}>
                                {
                                    moment(detailedTime.date).format('YYYY.MM.DD') + '   ' +
                                    (detailedTime.hour < 10 ? ('0' + detailedTime.hour) : detailedTime.hour) + ':' +
                                    (detailedTime.minute < 10 ? ('0' + detailedTime.minute) : detailedTime.minute)
                                }
                            </Text>
                            <View style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginTop: perfectSize(8),
                                marginBottom: perfectSize(10)
                            }}>
                                <Text style={
                                    [globalStyle.normalWhiteText, {
                                        marginRight: perfectSize(-12),
                                    }]}>
                                    {performance.location.name}
                                </Text>
                                <Image
                                    source={require('../../../../assets/images/ic_before.png')}
                                    style={styles.optionNextIcon}
                                />
                            </View>
                            <View style={globalStyle.flexRow}>
                                <Text style={[globalStyle.normalWhiteText, {
                                    fontSize: perfectSize(13),
                                }]}>
                                    {`총 ${selectedSeat.length}매 예매중`}
                                </Text>
                                <Text style={{
                                    backgroundColor: c_white,
                                    borderRadius: perfectSize(2),
                                    paddingHorizontal: perfectSize(9),
                                    paddingVertical: perfectSize(4),
                                    marginLeft: perfectSize(12),
                                    overflow: 'hidden'
                                }}>
                                    {orderDetail ? orderDetail.deliveryType : ''}
                                </Text>
                            </View>
                        </View>
                        <Image
                            source={{uri: UPLOAD_URL + performance.poster_link}}
                            style={styles.banner}
                        />
                    </View>
                    <Collapsible
                        duration={400}
                        collapsed={isCollapsed}>
                        <View style={styles.collapsedContent}>
                            {
                                selectedSeat.map((item, index) => {
                                    const seatData = item.split(":");
                                    const name = `${seatData[4]}석 ` + (seatData[0] !== '0' ? `${seatData[0]}층 ` : '') +
                                        (seatData[1] !== '0' ? `${seatData[1]}구역 ` : '') +
                                        (seatData[2] !== '0' ? `${seatData[2]}열 ` : '') +
                                        `${seatData[3]}번`;
                                    return (
                                        <TouchableOpacity key={index} style={styles.sitNumber}>
                                            <Text style={styles.sitNumberLabel}>
                                                {name}
                                            </Text>
                                        </TouchableOpacity>
                                    )
                                })
                            }
                        </View>
                    </Collapsible>

                    <TouchableOpacity onPress={() => {
                        setNavUp(!isNavUp);
                        setCollapsed(!isCollapsed);
                    }}>
                        <Image
                            source={
                                !isNavUp ?
                                    require('../../../../assets/images/ic_ticket_more.png') :
                                    require('../../../../assets/images/ic_ticket_more_close.png')
                            }
                            style={styles.knob}
                        />
                    </TouchableOpacity>
                </ImageBackground>
            </View>
        )
    }

    if (_.isEmpty(orderDetail)) return null;
    return (
        <View style={globalStyle.mainContainer}>
            {renderHeader()}
            <KeyboardAwareScrollView ref={refScrollView}>
                {renderCollapsibleView()}
                <View style={styles.section}>
                    <Text style={globalStyle.regularBoldText}>
                        {'관객정보'}
                    </Text>
                    <View style={styles.sectionContent}>
                        {
                            orderDetail.deliveryType !== '모바일티켓' ?
                                <View style={[globalStyle.flexRow, {marginBottom: perfectSize(10)}]}>
                                    <Text style={[globalStyle.regularText, {
                                        width: perfectSize(90)
                                    }]}>
                                        {'전화번호'}
                                    </Text>
                                    <TextInput
                                        value={phoneNumber}
                                        keyboardType={'number-pad'}
                                        placeholder={'휴대폰번호를 입력해주세요.'}
                                        onChangeText={text => setPhoneNumber(text)}
                                        style={[styles.textInput, {padding: perfectSize(10)}]}
                                    />
                                </View>
                                :
                                <View>
                                    {
                                        selectedNum.map((item, index) => {
                                            const seatData = selectedSeat[index].split(":");
                                            const name = (seatData[0] !== '0' ? `${seatData[0]}층 ` : '') +
                                                (seatData[1] !== '0' ? `${seatData[1]}구역 ` : '') +
                                                (seatData[2] !== '0' ? `${seatData[2]}열 ` : '') +
                                                `${seatData[3]}번`;
                                            return (
                                                <>
                                                    <Text style={[globalStyle.regularText, {
                                                        fontWeight: 'bold',
                                                        marginBottom: perfectSize(10)
                                                    }]}>
                                                        {name}
                                                    </Text>
                                                    <View
                                                        style={[globalStyle.flexRow, {marginBottom: perfectSize(10)}]}>
                                                        <Text style={[globalStyle.regularText, {
                                                            width: perfectSize(90)
                                                        }]}>
                                                            {'전화번호'}
                                                        </Text>
                                                        <TextInput
                                                            placeholder={'휴대폰번호를 입력해주세요.'}
                                                            keyboardType={'number-pad'}
                                                            onChangeText={text => {
                                                                phoneNumberGroup[name] = text;
                                                                setPhoneNumberGroup(phoneNumberGroup);
                                                            }}
                                                            style={[styles.textInput, {padding: perfectSize(10)}]}
                                                        />
                                                    </View>
                                                </>
                                            )
                                        })
                                    }
                                </View>
                        }
                        {/*<View style={[globalStyle.flexRow, { marginBottom: perfectSize(10) }]}>*/}
                        {/*    <Text style={[globalStyle.regularText, {*/}
                        {/*        width: perfectSize(90)*/}
                        {/*    }]}>*/}
                        {/*        {'이메일'}*/}
                        {/*    </Text>*/}
                        {/*    <TextInput*/}
                        {/*        value={email}*/}
                        {/*        keyboardType={'email-address'}*/}
                        {/*        placeholder={'이메일을 입력하세요.'}*/}
                        {/*        onChangeText={text => setEmail(text)}*/}
                        {/*        style={[styles.textInput, { padding: perfectSize(10) }]}*/}
                        {/*    />*/}
                        {/*</View>*/}
                    </View>
                </View>
                <Separator
                    height={perfectSize(8)}
                    color={c_second_white}
                />

                {/** 배송지 */}
                {
                    orderDetail.deliveryType === '배송' &&
                    <>
                        <View style={styles.section}>
                            <Text style={globalStyle.regularBoldText}>
                                {'배송지'}
                            </Text>
                            {
                                deliveryAddress &&
                                <View style={styles.card}>
                                    <View style={globalStyle.flexSpaceBetween}>
                                        <Text style={styles.name}>
                                            {deliveryAddress.receiver}
                                        </Text>
                                    </View>
                                    <Text style={[globalStyle.regularBoldText, {marginTop: perfectSize(10)}]}>
                                        {deliveryAddress.phoneNum}
                                    </Text>

                                    <Text style={styles.idNumber}>
                                        {`[${deliveryAddress.post_code}]`}
                                    </Text>
                                    <Text style={globalStyle.smText}>
                                        {`${deliveryAddress.address1} ${deliveryAddress.address2}`}
                                    </Text>
                                </View>
                            }

                            <View style={styles.sectionContent}>
                                <Button
                                    onPress={() => navigation.navigate('ShippingAddress')}
                                    textColor={c_primary_red}
                                    label={deliveryAddress ? '배송지 변경' : '배송지 입력'}
                                    color={c_white}
                                    isBorder={true}
                                    borderColor={c_primary_red}
                                />
                            </View>
                        </View>
                        <Separator
                            height={perfectSize(8)}
                            color={c_second_white}
                        />
                    </>

                }

                {/** 금액상세 */}
                <View style={styles.section}>
                    <Text style={globalStyle.regularBoldText}>
                        {'금액상세'}
                    </Text>

                    <View style={styles.sectionContent}>
                        {
                            rewardPoint > 0 ?
                                <View>
                                    <View style={globalStyle.flexSpaceBetween}>
                                        <Text style={globalStyle.regularRedBoldText}>
                                            {'총 결제금액'}
                                        </Text>
                                        <Text style={[styles.largeRewardPriceLabel, {
                                            textDecorationLine: 'line-through'
                                        }]}>
                                            {`${numberWithCommas(totalPrice ? totalPrice : 0)}원`}
                                        </Text>
                                    </View>
                                    <View style={[globalStyle.flexSpaceBetween, {
                                        justifyContent: 'flex-end',
                                    }]}>
                                        <Text style={styles.largePriceLabel}>
                                            {`${numberWithCommas(rewardPrice ? rewardPrice : 0)}원`}
                                        </Text>
                                    </View>
                                </View>
                                :
                                <View style={globalStyle.flexSpaceBetween}>
                                    <Text style={globalStyle.regularRedBoldText}>
                                        {'총 결제금액'}
                                    </Text>
                                    <Text style={styles.largePriceLabel}>
                                        {`${numberWithCommas(totalPrice ? totalPrice : 0)}원`}
                                    </Text>
                                </View>
                        }
                        <Separator
                            height={perfectSize(1)}
                            color={c_second_white}
                            marginTop={perfectSize(15)}
                            marginBottom={perfectSize(15)}
                        />

                        <View>
                            <View style={globalStyle.flexSpaceBetween}>
                                <Text style={[globalStyle.regularText, {
                                    marginBottom: perfectSize(10),
                                    width: perfectSize(80)
                                }]}>
                                    {'티켓금액'}
                                </Text>
                                {
                                    rewardPoint > 0 ?
                                        <Text style={[globalStyle.regularText, {marginBottom: perfectSize(10)}]}>
                                            {`${numberWithCommas(rewardPrice ? rewardPrice : 0)}원`}
                                        </Text> :
                                        <Text style={[globalStyle.regularText, {marginBottom: perfectSize(10)}]}>
                                            {`${numberWithCommas(orderDetail ? orderDetail.ticketPrice : 0)}원`}
                                        </Text>
                                }
                            </View>
                            <View style={{
                                paddingHorizontal: perfectSize(15),
                                paddingVertical: perfectSize(12),
                                backgroundColor: c_dark_white,
                                marginBottom: perfectSize(10)
                            }}>
                                <View style={globalStyle.flexSpaceBetween}>
                                    <Text style={[globalStyle.regularGreyText, {
                                        marginBottom: perfectSize(10),
                                        width: perfectSize(80)
                                    }]}>
                                        {'기본가'}
                                    </Text>
                                    <Text style={[globalStyle.regularGreyText, {marginBottom: perfectSize(10)}]}>
                                        {`${numberWithCommas(orderDetail ? orderDetail.realPrice : 0)}원`}
                                    </Text>
                                </View>
                                <View style={globalStyle.flexSpaceBetween}>
                                    <Text style={[globalStyle.regularGreyText, {
                                        marginBottom: perfectSize(10),
                                        width: perfectSize(80)
                                    }]}>
                                        {'가격할인'}
                                    </Text>
                                    {
                                        rewardPoint > 0 ?
                                            <Text
                                                style={[globalStyle.regularGreyText, {marginBottom: perfectSize(10)}]}>
                                                {`${numberWithCommas(rewardPrice ? totalPrice - rewardPrice : 0)}원`}
                                            </Text> :
                                            <Text
                                                style={[globalStyle.regularGreyText, {marginBottom: perfectSize(10)}]}>
                                                {`${numberWithCommas(orderDetail ? orderDetail.ticketPrice - orderDetail.realPrice : 0)}원`}
                                            </Text>
                                    }
                                </View>
                                <View style={globalStyle.flexSpaceBetween}>
                                    <Text style={[globalStyle.regularGreyText, {
                                        marginBottom: perfectSize(10),
                                        width: perfectSize(80)
                                    }]}>
                                        {'쿠폰'}
                                    </Text>
                                    <Text style={[globalStyle.regularGreyText, {marginBottom: perfectSize(10)}]}>
                                        {`${numberWithCommas(orderDetail ? orderDetail.couponPrice : 0)}원`}
                                    </Text>
                                </View>
                                <View style={globalStyle.flexSpaceBetween}>
                                    <Text style={[globalStyle.regularGreyText, {width: perfectSize(80)}]}>
                                        {'초대권'}
                                    </Text>
                                    <Text style={globalStyle.regularGreyText}>
                                        {`${numberWithCommas(orderDetail ? orderDetail.invitationPrice : 0)}원`}
                                    </Text>
                                </View>
                            </View>
                            <View style={globalStyle.flexSpaceBetween}>
                                <Text style={[globalStyle.regularText, {
                                    marginBottom: perfectSize(10),
                                    width: perfectSize(80)
                                }]}>
                                    {'배송비'}
                                </Text>
                                <Text style={[globalStyle.regularText, {marginBottom: perfectSize(10)}]}>
                                    {`${numberWithCommas(orderDetail ? orderDetail.deliveryFee : 0)}원`}
                                </Text>
                            </View>
                            <View style={globalStyle.flexSpaceBetween}>
                                <Text style={[globalStyle.regularText, {
                                    marginBottom: perfectSize(20),
                                    width: perfectSize(80)
                                }]}>
                                    {'예매수수료'}
                                </Text>
                                <Text style={[globalStyle.regularText, {marginBottom: perfectSize(20)}]}>
                                    {`${numberWithCommas(orderDetail ? orderDetail.fee : 0)}원`}
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
                <Separator
                    height={perfectSize(8)}
                    color={c_second_white}
                />

                {/** 결제방식 */}
                {
                    totalPrice ?
                        <>
                            <View style={styles.section}>
                                <Text style={globalStyle.regularBoldText}>
                                    {'결제방식'}
                                </Text>

                                <View style={styles.sectionContent}>
                                    <View style={globalStyle.flexRow}>
                                        <RadioButton
                                            label={'신용카드'}
                                            isSelected={paymentType === 1}
                                            onSelect={val => {
                                                setPaymentType(val ? 1 : 0);
                                                if (isSaveCardInfo[0]?.cardNum.length > 0) {
                                                    setIsSavePriceExtend(!isSavePriceExtend)
                                                } else {
                                                    setIsPriceExtend(!isPriceExtend)
                                                }
                                            }}
                                        />
                                        <View style={{flex: 1}}>
                                            <Text
                                                style={{
                                                    fontSize: perfectSize(12),
                                                    textAlign: "right",
                                                    color: c_primary_red,
                                                    letterSpacing: -0.1,
                                                    marginRight: perfectSize(5)
                                                }}
                                            >
                                                {
                                                    (rewardPoint > 0 || (user && user.subscribe && user.subscribe[0]?.type === "MANIA"))
                                                        ? "매니아회원 할인 (70% 할인, 한도 내)"
                                                        : ""
                                                }
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <Collapsible
                                collapsed={isSavePriceExtend}>
                                <Separator
                                    height={perfectSize(8)}
                                    color={c_second_white}
                                    marginBottom={perfectSize(20)}
                                />

                                <View style={{marginLeft: perfectSize(15), marginRight: perfectSize(15)}}>
                                    {
                                        isSaveCardInfo.map((item, index) => {
                                            return (
                                                <View key={index}>
                                                    <View style={{flexDirection: 'row'}}>
                                                        <CheckBox
                                                            isSelected={isChoiceCard[index]}
                                                            onSelect={() => {
                                                                let newSelection = [...isChoiceCard];
                                                                newSelection[index] = !newSelection[index];

                                                                setIsChoiceCard(newSelection);
                                                                setUseDifferentCard(false);
                                                                if (newSelection[index]) {
                                                                    setChoiceCardInfo(index);
                                                                    setIsPriceExtend(true);
                                                                    setUseSaveData(index);
                                                                }
                                                            }}
                                                            label={item.cardNum}
                                                            color={c_third_black}
                                                            style={{
                                                                marginBottom: perfectSize(21),
                                                                width: perfectSize(167)
                                                            }}
                                                        />
                                                        <Text style={[globalStyle.regularText, {
                                                            marginBottom: perfectSize(10),
                                                            marginRight: perfectSize(45),
                                                            width: perfectSize(128),
                                                            // paddingTop: perfectSize(5),
                                                        }]}>등록된 카드로 결제</Text>
                                                    </View>
                                                </View>
                                            )
                                        })
                                    }
                                    <CheckBox
                                        isSelected={useDifferentCard}
                                        onSelect={() => {
                                            if (useDifferentCard) {
                                                console.log("체크 해제됨");
                                                setIsPriceExtend(true);
                                            } else {
                                                setIsPriceExtend(false);
                                                setIsChoiceCard(Array(isChoiceCard.length).fill(false));
                                                console.log("체크됨");
                                            }
                                            setUseDifferentCard(!useDifferentCard);
                                        }}
                                        label={'다른 카드로 결제'}
                                        color={c_third_black}
                                        style={{marginBottom: perfectSize(21), backgroundColor: c_fourth_white}}
                                    />
                                </View>
                            </Collapsible>
                            <Collapsible
                                collapsed={isPriceExtend}>
                                <Separator
                                    height={perfectSize(8)}
                                    color={c_second_white}
                                    marginBottom={perfectSize(20)}
                                />
                                <View style={{marginLeft: perfectSize(15), marginRight: perfectSize(15)}}>
                                    <Text style={[globalStyle.regularText, {
                                        marginBottom: perfectSize(10),
                                        width: perfectSize(80)
                                    }]}>카드번호</Text>
                                    <TextInput
                                        value={isCardNumber}
                                        placeholder={'0000 0000 0000 0000'}
                                        onChangeText={text => {
                                            setIsCardNumber(text)
                                        }}
                                        style={[styles.priceInput, {marginBottom: perfectSize(20)}]}
                                    />
                                    <View style={{flexDirection: 'row'}}>
                                        <Text style={[globalStyle.regularText, {
                                            marginBottom: perfectSize(10),
                                            width: '50%'
                                        }]}>유효기간 (월/년)</Text>
                                        <Text style={[globalStyle.regularText, {
                                            marginBottom: perfectSize(10),
                                            width: '50%'
                                        }]}>비밀번호 앞 2자리</Text>
                                    </View>
                                    <View style={{flexDirection: 'row'}}>
                                        <TextInput
                                            value={isExpirationDate}
                                            placeholder={'MM/YY'}
                                            onChangeText={text => {
                                                setIsExpirationDate(text)
                                                const formattedText = text.replace(/[^0-9]/g, '');
                                                const month = formattedText.slice(0, 2);
                                                const year = formattedText.slice(2, 4);
                                                const formattedDate = `${year}${month}`;
                                                setIsChangeDate(formattedDate);
                                            }}
                                            style={[styles.priceInput, {width: '50%', marginBottom: perfectSize(20)}]}
                                        />
                                        <TextInput
                                            value={isPassword}
                                            placeholder={'**'}
                                            secureTextEntry={true}
                                            onChangeText={text => {
                                                setIsPassword(text)
                                            }}
                                            style={[styles.priceInput, {width: '50%', marginBottom: perfectSize(20)}]}
                                        />
                                    </View>
                                    <Text style={globalStyle.regularText}>카드 소유주 주민등록번호 앞 6자리</Text>
                                    <Text style={[globalStyle.regularText, {marginBottom: perfectSize(10)}]}>(법인카드는
                                        사업자번호 10자리)</Text>
                                    <TextInput
                                        value={isUserIdNumber}
                                        placeholder={'입력해주세요.'}
                                        onChangeText={text => {
                                            setIsUserIdNumber(text)
                                        }}
                                        style={[styles.priceInput, {marginBottom: perfectSize(22)}]}
                                    />
                                    {/*<CheckBox*/}
                                    {/*    isSelected={useAppCard}*/}
                                    {/*    onSelect={() => {*/}
                                    {/*        setUseAppCard(!useAppCard);*/}
                                    {/*    }}*/}
                                    {/*    label={'카드사 앱으로 결제하기 (앱카드, 간편결제 등)'}*/}
                                    {/*    color={c_third_black}*/}
                                    {/*    style={{marginBottom: perfectSize(21)}}*/}
                                    {/*/>*/}
                                </View>
                            </Collapsible>


                            <Separator
                                height={perfectSize(8)}
                                color={c_second_white}
                            />
                        </>
                        : null
                }
                {/** 이용약관 */}
                <View style={styles.section}>
                    <Text style={globalStyle.regularBoldText}>
                        {'이용약관'}
                    </Text>

                    <View style={styles.sectionContent}>
                        <CheckBox
                            isSelected={termAll}
                            onSelect={() => {
                                setTermAll(!termAll);
                                setTerm1(!termAll);
                                setTerm2(!termAll);
                                setCountTerms(!termAll ? 2 : 0)
                            }}
                            label={'전체동의'}
                        />
                        <Separator
                            height={perfectSize(1)}
                            color={c_second_white}
                            marginTop={perfectSize(12)}
                            marginBottom={perfectSize(15)}
                        />
                        <View style={{flexDirection: 'row'}}>
                            <CheckBox
                                isSelected={term1}
                                onSelect={() => {
                                    if (term1) {
                                        setCountTerms(countTerms - 1)
                                    } else {
                                        setCountTerms(countTerms + 1)
                                    }
                                    setTerm1(!term1);
                                }}
                                label={
                                    <Text style={{
                                        fontSize: perfectSize(13),
                                        letterSpacing: -0.1,
                                        color: c_fourth_grey,
                                    }}>
                                        제3자 정보제공 동의 (필수)
                                    </Text>
                                }
                                color={c_grey}
                            />
                            <TouchableOpacity
                                style={{marginLeft: perfectSize(8)}}
                                onPress={() => {
                                    setVisibleAlertModal(true);
                                }}>
                                <Text style={{
                                    fontSize: perfectSize(13),
                                    letterSpacing: -0.13,
                                    color: c_fourth_grey,
                                    marginTop: perfectSize(1),
                                    textDecorationLine: 'underline'
                                }}>
                                    {'자세히 보기 >'}
                                </Text>
                            </TouchableOpacity>
                        </View>
                        <Separator
                            height={perfectSize(1)}
                            color={c_second_white}
                            marginTop={perfectSize(12)}
                            marginBottom={perfectSize(15)}
                        />
                        <View style={{flexDirection: 'row'}}>
                            <CheckBox
                                isSelected={term2}
                                onSelect={() => {
                                    if (term2) {
                                        setCountTerms(countTerms - 1)
                                    } else {
                                        setCountTerms(countTerms + 1)
                                    }
                                    setTerm2(!term2);
                                }}
                                label={
                                    <Text style={{
                                        fontSize: perfectSize(13),
                                        letterSpacing: -0.1,
                                        color: c_fourth_grey,
                                    }}>
                                        취소규정 동의 (필수)
                                    </Text>
                                }
                                color={c_grey}
                            />
                            <TouchableOpacity
                                style={{marginLeft: perfectSize(8)}}
                                onPress={() => {
                                    setVisibleAlertModal(true);
                                }}>
                                <Text style={{
                                    fontSize: perfectSize(13),
                                    letterSpacing: -0.13,
                                    color: c_fourth_grey,
                                    marginTop: perfectSize(1),
                                    textDecorationLine: 'underline'
                                }}>
                                    {'자세히 보기 >'}
                                </Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </KeyboardAwareScrollView>
            {bottomView()}
            {visibleCardModal &&
                <CardModal
                    isVisible={visibleCardModal}
                    hideModal={() => {
                        setVisibleCardModal(false);
                    }}
                    onSuccess={onSuccess}
                    moid={`ttticket0m${currentTimeRef.current}`}
                    data={`https://admin.tickettong.net/card-pay?product_name=${encodeURIComponent(performance.title.name)}&price=${totalPrice}&buyer_name=${encodeURIComponent(user.nickname || user.decPhoneNum)}&buyer_phone=${user.decPhoneNum}&buyer_email=${email}&moid=ttticket0m${currentTimeRef.current}`}
                />
            }
            {
                visibleAlertModal &&
                <OneAlertModal
                    isVisible={visibleAlertModal}
                    hideModal={() => {
                        setVisibleAlertModal(false);
                    }}
                    contents={
                        isTermsContents[1].content
                    }
                    txt_type={"term"}
                />
            }
        </View>
    )
}

const mapStateTopProps = state => ({
    user: state.auth.user,
    orderDetail: state.seat.orderDetail,
    totalPrice: state.seat.totalPrice,
    selectedSeat: state.seat.selectedSeat,
    selectedNum: state.seat.selectedNum,
});

export default connect(mapStateTopProps, {setOrderDetail})(Checkout);

const styles = {
    section: {
        paddingVertical: perfectSize(20),
        paddingHorizontal: perfectSize(15)
    },
    sectionContent: {
        marginTop: perfectSize(20),
        marginBottom: perfectSize(5)
    },
    sectionHeaderTitle: {
        fontSize: perfectSize(13),
        color: c_grey,
        letterSpacing: -0.15,
        marginBottom: perfectSize(7)
    },
    textInput: {
        fontSize: perfectSize(14),
        letterSpacing: -0.15,
        color: c_primary_black,
        flex: 1,
        borderWidth: 1,
        borderColor: c_light_grey,
        borderRadius: perfectSize(6),
        paddingHorizontal: perfectSize(15),
        height: perfectSize(46),
        marginRight: perfectSize(5),
    },
    coin: {
        width: perfectSize(16),
        height: perfectSize(16),
        resizeMode: 'contain',
        marginRight: perfectSize(5)
    },
    questionIcon: {
        width: perfectSize(16),
        height: perfectSize(16),
        resizeMode: 'contain',
        marginLeft: perfectSize(5)
    },
    largePriceLabel: {
        fontSize: perfectSize(20),
        fontWeight: 'bold',
        color: c_primary_red
    },
    largeRewardPriceLabel: {
        fontSize: perfectSize(14),
        letterSpacing: -0.01,
        color: c_primary_black
    },
    bottomButtons: {
        backgroundColor: c_white,
        justifyContent: 'flex-end',
        marginBottom: perfectSize(30)

    },
    headerDate: {
        backgroundColor: '#555555',
        alignItems: 'center',
        paddingVertical: perfectSize(12),
        height: perfectSize(40),
        justifyContent: 'flex-start'
    },
    optionNextIcon: {
        width: perfectSize(4),
        height: perfectSize(8),
        resizeMode: 'contain',
        tintColor: c_white,
        marginHorizontal: perfectSize(20),
        transform: [{rotate: '180deg'}]
    },
    collapsedView: {
        backgroundColor: 'black',
        overflow: 'hidden',
        borderBottomLeftRadius: perfectSize(20),
        borderBottomRightRadius: perfectSize(20),
    },
    collapsedContent: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginRight: perfectSize(-15),
    },
    imageBackground: {
        flex: 1,
        padding: perfectSize(15),
        paddingTop: perfectSize(20),
    },
    blurContainer: {
        backgroundColor: '#000000b3',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    knob: {
        width: perfectSize(34),
        height: perfectSize(11),
        resizeMode: 'contain',
        alignSelf: 'center',
        marginTop: perfectSize(20)
    },
    banner: {
        width: perfectSize(65),
        height: perfectSize(90),
        resizeMode: 'stretch',
        borderRadius: perfectSize(6),
        backgroundColor: c_light_grey
    },
    sitNumber: {
        borderWidth: 1,
        borderColor: c_white,
        width: '44%',
        height: perfectSize(24),
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: perfectSize(15),
        marginTop: perfectSize(15)
    },
    sitNumberLabel: {
        fontSize: perfectSize(11),
        color: c_white,
    },
    card: {
        borderWidth: 1,
        borderColor: c_third_grey,
        borderRadius: perfectSize(6),
        padding: perfectSize(20),
        marginBottom: perfectSize(10),
        marginTop: perfectSize(15)
    },
    name: {
        fontSize: perfectSize(15),
        letterSpacing: -0.15,
        fontWeight: 'bold',
        color: c_black
    },
    editButton: {
        fontSize: perfectSize(14),
        letterSpacing: -0.15,
        color: c_primary_black
    },
    separator: {
        width: 1,
        height: perfectSize(12),
        backgroundColor: c_second_grey,
        marginHorizontal: perfectSize(8)
    },
    idNumber: {
        fontSize: perfectSize(12),
        letterSpacing: -0.15,
        color: c_black,
        marginTop: perfectSize(10),
        marginBottom: perfectSize(5)
    },
    priceInput: {
        fontSize: perfectSize(14),
        color: c_primary_black,
        borderWidth: 1,
        borderColor: c_third_grey,
        borderRadius: perfectSize(6),
        height: perfectSize(36),
        paddingLeft: perfectSize(12)
    },
    btn_priceclose: {
        width: perfectSize(16),
        height: perfectSize(16),
        resizeMode: 'contain',
        alignSelf: 'center',
    },
}