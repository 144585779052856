import React, {useEffect, useRef, useState} from "react";
import {
    ActivityIndicator,
    Alert,
    Image, Linking,
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View
} from "react-native";
import {globalStyle, perfectSize} from "../../variables/styles";
import {c_light_grey, c_primary_black, c_primary_red, c_third_grey, c_white} from "../../variables/colors";
import {oneAlert} from "../../utils";
import * as FileSystem from "expo-file-system";
import ReactPlayer from "react-player";
import Button from "../../components/Button";
import {getContentStatus, setContentStatus} from "../../store/actions/performance";
import CardModal from "../../modals/CardModal";
import axios from "axios";
import {HOST} from "../../variables/constants";
import {getRewardPoint} from "../../store/actions";

export default function ContentDetail({route, navigation}) {
    const {performance, index, item, user} = route.params;
    const [isMorePlayer, showMorePlayer] = useState(false);
    const [isMoreMusic, showMoreMusic] = useState(false);
    const [isBuyContents, setBuyContents] = useState(false);

    const currentTimeRef = useRef(null);
    const [visibleCardModal, setVisibleCardModal] = useState(false);
    const [email, setEmail] = useState('ticket@etomato.com');
    const [rewardPoint, setRewardPoint] = useState(0);
    const [isPrice, setPrice] = useState(0);
    const [disCountPoint, setDisCountPoint] = useState(0);

    //결제
    function Payment() {
        if (user) {
            // navigation.navigate('Terms', {type: 5, user: user, paytype: 1, item: item});
            setVisibleCardModal(true);
            const newDate = new Date().getTime();
            currentTimeRef.current = newDate;
            const moid = "ttticket0m" + newDate;
            onCreateCard(moid);
        } else {
            navigation.navigate("SignIn", {screenName: 'Home'});
        }
    }

    const onCreateCard = (moid) => {
        axios.post(`${HOST}/api/tongtongcoin/createCardPay`, {moid})
            .then(res => {
                // onCheckCard(moid);
            })
            .catch(err => {
                oneAlert('카드결제 서버에 접속할수 없습니다. 다시 시도해주세요.');
            })
    }

    const onSuccess = async (TID) => {
        setVisibleCardModal(false);

        await setContentStatus(user?._id, item?._id, isPrice, `ttticket0m${currentTimeRef.current}`).then(
            res => {
                if (res.status === 1) {
                    navigation.navigate("ManiaItemReturn", {user: user});
                }
            }
        ).catch(err => console.log('onSuccess err = ' + err))
    };

    const onHideModal = async () => {
        setVisibleCardModal(false);
        await setContentStatus(user?._id, item?._id, isPrice, `ttticket0m${currentTimeRef.current}`).then(
            res => {
                if (res.status === 1) {
                    navigation.navigate("ManiaItemReturn", {user: user});
                }
            }
        ).catch(err => console.log('onHideModal err = ' + err))
    }

    // 다운로드
    const onDownload = (filePath, fileName) => {
        const fileUri = FileSystem.documentDirectory + encodeURI(fileName);
        downloadFile(filePath, fileName);
    };

    const downloadFile = (filePath, fileName) => {
        const fileUri = FileSystem.documentDirectory + encodeURI(fileName);
        fetch(fileUri, {method: 'GET'})
            .then((res) => {
                return res.blob();
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = filePath;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                setTimeout((_) => {
                    window.URL.revokeObjectURL(url);
                }, 60000);
                a.remove();
            })
            .catch((err) => {
                oneAlert("다운로드가 실패하였습니다.");
                console.error('downloadFile err: ', err);
            });
    };

    // 다운로드
    useEffect(() => {
        navigation.setOptions({
            title: performance ? performance.contents[index].title.length > 20 ? performance.contents[index].title.substring(0, 18) + "..." : performance.contents[index].title : "",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require("../../../assets/images/ic_before_stroke.png")}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            ),
        });
        setPrice(item?.price)
        getRewardData();
    }, []);

    useEffect(() => {
        if (user) {
            getContentStatus(user._id, performance?._id, item?._id).then(
                res => {
                    setBuyContents(res.data.isBuyContents);
                });
        }
    }, [user]);

    useEffect(() => {
        if (rewardPoint > 0) {
            const percentage = 70;
            const result = (isPrice * percentage) / 100; // 70퍼센트 금액
            if (rewardPoint - result > 0) { //70퍼센트 금액 이상포인트 있는경우
                setPrice(item?.price - result);
            } else { // 아닌경우
                setPrice(isPrice - rewardPoint)
            }
            setDisCountPoint(item?.price - isPrice)
        }
    }, [rewardPoint]);

    const getRewardData = async () => {
        if (user) {
            await getRewardPoint(user._id).then(res => {
                if (res.Code === "200") {
                    const point = parseInt(res.data.Point);
                    setRewardPoint(point);
                }
            });
        }
    }

    return (
        <View style={styles.container}>
            <ScrollView>
                <ReactPlayer
                    url={item.video?.url}
                    height={perfectSize(220)}
                    width={'100%'}
                    muted={true}
                    playing={true}
                    controls={true}
                />
                {item?.duration === 0 ?
                    <View style={{flexDirection: 'row', marginTop: perfectSize(10), marginBottom: perfectSize(20)}}>
                        <Text style={[styles.runtimetext, {
                            marginRight: perfectSize(20)
                        }]}>재생시간</Text>
                        <Text style={styles.runtimetext}>
                            {item?.duration}</Text>
                    </View> : <View/>

                }
                <View style={{flexDirection: 'row', marginTop: perfectSize(10), marginBottom: perfectSize(20)}}>
                    <Text style={[styles.runtimetext, {
                        marginRight: perfectSize(20)
                    }]}>재생시간</Text>
                    <Text style={styles.runtimetext}>
                        {item?.duration}</Text>
                </View>
                <View style={{backgroundColor: "#eeeeee", height: perfectSize(3), marginBottom: perfectSize(39)}}/>

                {/*4K Atmos atmos*/}
                {item?.atmos === undefined ? <View/> :
                    <View style={{
                        flexDirection: 'row',
                        marginTop: perfectSize(10),
                        marginBottom: perfectSize(8),
                        alignItems: 'center'
                    }}>
                        <Text style={[styles.contentstitletext, {width: '25%'}]}>4K Atmos</Text>
                        <Image style={styles.contentimage}
                               source={require('../../../assets/images/content_info2.png')}/>
                        <TouchableOpacity
                            onPress={() => {
                                if (item.atmos?.content?.is_premium) {
                                    if (((user?.subscribe && user?.subscribe[0]?.type === "MANIA") || performance.buyer_benefit || isBuyContents)) {
                                        onDownload("https://file.tickettong.net/" + item.atmos?.url, item.atmos?.url);
                                    } else {
                                        oneAlert(`매니아 회원 또는 결제 후\n 이용 가능합니다.`);
                                    }
                                } else {
                                    if (user) {
                                        onDownload("https://file.tickettong.net/" + item.atmos?.url, item.atmos?.url);
                                    } else {
                                        navigation.navigate("SignIn", {screenName: 'Home'});
                                    }
                                }
                            }}>
                            <Text style={[styles.contentstext, {marginRight: perfectSize(10)},]}>다운로드</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            disabled={true}
                            onPress={() => {
                                if (item.atmos?.content?.is_premium) {
                                    if ((user?.subscribe && user?.subscribe[0]?.type === "MANIA") || performance.buyer_benefit || isBuyContents) {
                                        navigation.navigate("VideoPlayer", {video: item.atmos, type: item.type});
                                    } else {
                                        oneAlert(`매니아 회원 또는 결제 후\n 이용 가능합니다.`);
                                    }
                                } else {
                                    if (user) {
                                        navigation.navigate("VideoPlayer", {video: item.atmos, type: item.type});
                                    } else {
                                        navigation.navigate("SignIn", {screenName: 'Home'});
                                    }
                                }
                            }}>
                            <Text
                                style={[styles.contentstext, {marginRight: perfectSize(10)}, {opacity: 0}]}>스트리밍</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => {
                                navigation.navigate('NFTMolDetail', {id: item.atmos?.nft});
                            }} style={{opacity: item.atmos?.nft ? 1 : 0}}>
                            <Text style={[styles.contentstext, {marginRight: perfectSize(10)},]}>NFT</Text>
                        </TouchableOpacity>
                    </View>
                }
                {/*VR360*/}
                {item?.vr360 === undefined ? <View/> :
                    <View style={{
                        flexDirection: 'row',
                        marginTop: perfectSize(10),
                        marginBottom: perfectSize(8),
                        alignItems: 'center'
                    }}>
                        <Text style={[styles.contentstitletext, {width: '25%'}]}>VR360</Text>
                        <Image style={styles.contentimage}
                               source={require('../../../assets/images/content_info2.png')}/>
                        <TouchableOpacity
                            onPress={() => {
                                if (item.vr360?.content?.is_premium) {
                                    if ((user?.subscribe && user?.subscribe[0]?.type === "MANIA") || performance.buyer_benefit || isBuyContents) {
                                        onDownload("https://file.tickettong.net/" + item.vr360?.url, item.vr360?.url)
                                    } else {
                                        oneAlert(`매니아 회원 또는 결제 후\n 이용 가능합니다.`);
                                    }
                                } else {
                                    if (user) {
                                        onDownload("https://file.tickettong.net/" + item.vr360?.url, item.vr360?.url)
                                    } else {
                                        navigation.navigate("SignIn", {screenName: 'Home'});
                                    }
                                }
                            }}>
                            <Text style={[styles.contentstext, {marginRight: perfectSize(10)},]}>다운로드</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => {
                                if (item.vr360?.content?.is_premium) {
                                    if ((user?.subscribe && user?.subscribe[0]?.type === "MANIA") || performance.buyer_benefit || isBuyContents) {
                                        navigation.navigate("VideoPlayer", {video: item.vr360, type: item.type});
                                    } else {
                                        oneAlert(`매니아 회원 또는 결제 후\n 이용 가능합니다.`);
                                    }
                                } else {
                                    if (user) {
                                        navigation.navigate("VideoPlayer", {video: item.vr360, type: item.type});
                                    } else {
                                        navigation.navigate("SignIn", {screenName: 'Home'});
                                    }
                                }
                            }}>
                            <Text style={[styles.contentstext, {marginRight: perfectSize(10)},]}>스트리밍</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => {
                                navigation.navigate('NFTMolDetail', {id: item.vr360?.nft});
                            }} style={{opacity: item.vr360?.nft ? 1 : 0}}>
                            <Text style={[styles.contentstext, {marginRight: perfectSize(10)},]}>NFT</Text>
                        </TouchableOpacity>
                    </View>
                }
                {/*FLAC*/}
                {item?.flac === undefined ? <View/> :
                    <View style={{
                        flexDirection: 'row',
                        marginTop: perfectSize(10),
                        marginBottom: perfectSize(8),
                        alignItems: 'center'
                    }}>
                        <Text style={[styles.contentstitletext, {width: '25%'}]}>FLAC</Text>
                        <Image style={styles.contentimage}
                               source={require('../../../assets/images/content_info2.png')}/>
                        <TouchableOpacity
                            onPress={() => {
                                if (item.flac?.content?.is_premium) {
                                    if ((user?.subscribe && user?.subscribe[0]?.type === "MANIA") || performance.buyer_benefit || isBuyContents) {
                                        onDownload("https://file.tickettong.net/" + item.flac?.url, item.flac?.url)
                                    } else {
                                        oneAlert(`매니아 회원 또는 결제 후\n 이용 가능합니다.`);
                                    }
                                } else {
                                    if (user) {
                                        onDownload("https://file.tickettong.net/" + item.flac?.url, item.flac?.url)
                                    } else {
                                        navigation.navigate("SignIn", {screenName: 'Home'});
                                    }
                                }
                            }}>
                            <Text style={[styles.contentstext, {marginRight: perfectSize(10)},]}>다운로드</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => {
                                if (item.flac?.content?.is_premium) {
                                    if ((user?.subscribe && user?.subscribe[0]?.type === "MANIA") || performance.buyer_benefit || isBuyContents) {
                                        navigation.navigate("AudioPlayer", {audio: item.flac});
                                    } else {
                                        oneAlert(`매니아 회원 또는 결제 후\n 이용 가능합니다.`);
                                    }
                                } else {
                                    if (user) {
                                        navigation.navigate("AudioPlayer", {audio: item.flac});
                                    } else {
                                        navigation.navigate("SignIn", {screenName: 'Home'});
                                    }
                                }
                            }}>
                            <Text style={[styles.contentstext, {marginRight: perfectSize(10)},]}>스트리밍</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => {
                                navigation.navigate('NFTMolDetail', {id: item.flac?.nft});
                            }} style={{opacity: item.flac?.nft ? 1 : 0}}>
                            <Text style={[styles.contentstext, {marginRight: perfectSize(10)},]}>NFT</Text>
                        </TouchableOpacity>
                    </View>
                }
                {/*DSD128*/}
                {item?.dsd === undefined ? <View/> :
                    <View style={{
                        flexDirection: 'row',
                        marginTop: perfectSize(10),
                        marginBottom: perfectSize(8),
                        alignItems: 'center'
                    }}>
                        <Text style={[styles.contentstitletext, {width: '25%'}]}>DSD128</Text>
                        <Image style={styles.contentimage}
                               source={require('../../../assets/images/content_info2.png')}/>
                        <TouchableOpacity
                            onPress={() => {
                                if (item.dsd?.content?.is_premium) {
                                    if ((user?.subscribe && user?.subscribe[0]?.type === "MANIA") || performance.buyer_benefit || isBuyContents) {
                                        // navigation.navigate("AudioPlayer", {audio: item.dsd});
                                        onDownload("https://file.tickettong.net/" + item.dsd?.url, item.dsd?.url)
                                    } else {
                                        oneAlert(`매니아 회원 또는 결제 후\n 이용 가능합니다.`);
                                    }
                                } else {
                                    if (user) {
                                        // navigation.navigate("AudioPlayer", {audio: item.dsd});
                                        onDownload("https://file.tickettong.net/" + item.dsd?.url, item.dsd?.url)
                                    } else {
                                        navigation.navigate("SignIn", {screenName: 'Home'});
                                    }
                                }
                            }}>
                            <Text style={[styles.contentstext, {marginRight: perfectSize(10)},]}>다운로드</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            disabled={true}
                            onPress={() => {
                                if (item.dsd?.content?.is_premium) {

                                    if ((user?.subscribe && user?.subscribe[0]?.type === "MANIA") || performance.buyer_benefit || isBuyContents) {
                                        navigation.navigate("AudioPlayer", {audio: item.dsd});
                                    } else {
                                        oneAlert(`매니아 회원 또는 결제 후\n 이용 가능합니다.`);
                                    }
                                } else {
                                    if (user) {
                                        navigation.navigate("AudioPlayer", {audio: item.dsd});
                                    } else {
                                        navigation.navigate("SignIn", {screenName: 'Home'});
                                    }
                                }
                            }} style={{opacity: 0}}>
                            <Text style={[styles.contentstext, {marginRight: perfectSize(10)},]}>스트리밍</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => {
                                navigation.navigate('NFTMolDetail', {id: item.dsd?.nft});
                            }} style={{opacity: item.dsd?.nft ? 1 : 0}}>
                            <Text style={[styles.contentstext, {marginRight: perfectSize(10)},]}>NFT</Text>
                        </TouchableOpacity>
                    </View>
                }
                {/*DSD64*/}
                {item?.dsd64 === undefined ? <View/> :
                    <View style={{
                        flexDirection: 'row',
                        marginTop: perfectSize(10),
                        marginBottom: perfectSize(8),
                        alignItems: 'center'
                    }}>
                        <Text style={[styles.contentstitletext, {width: '25%'}]}>DSD64</Text>
                        <Image style={styles.contentimage}
                               source={require('../../../assets/images/content_info2.png')}/>
                        <TouchableOpacity
                            onPress={() => {
                                if (item?.dsd64?.content?.is_premium) {
                                    if ((user?.subscribe && user?.subscribe[0]?.type === "MANIA") || performance.buyer_benefit || isBuyContents) {
                                        onDownload("https://file.tickettong.net/" + item?.dsd64?.url, item?.dsd64?.url)
                                    } else {
                                        oneAlert(`매니아 회원 또는 결제 후\n 이용 가능합니다.`);
                                    }
                                } else {
                                    if (user) {
                                        onDownload("https://file.tickettong.net/" + item?.dsd64?.url, item?.dsd64?.url)
                                    } else {
                                        navigation.navigate("SignIn", {screenName: 'Home'});
                                    }
                                }
                            }}>
                            <Text style={[styles.contentstext, {marginRight: perfectSize(10)},]}>다운로드</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            disabled={true}
                            onPress={() => {
                                if (item.dsd64?.content?.is_premium) {

                                    if ((user?.subscribe && user?.subscribe[0]?.type === "MANIA") || performance.buyer_benefit || isBuyContents) {
                                        navigation.navigate("AudioPlayer", {audio: item?.dsd64});
                                    } else {
                                        oneAlert(`매니아 회원 또는 결제 후\n 이용 가능합니다.`);
                                    }
                                } else {
                                    if (user) {
                                        navigation.navigate("AudioPlayer", {audio: item?.dsd64});
                                    } else {
                                        navigation.navigate("SignIn", {screenName: 'Home'});
                                    }
                                }
                            }} style={{opacity: 0}}>
                            <Text style={[styles.contentstext, {marginRight: perfectSize(10)},]}>스트리밍</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => {
                                navigation.navigate('NFTMolDetail', {id: item?.dsd64?.nft});
                            }} style={{opacity: item?.dsd64?.nft ? 1 : 0}}>
                            <Text style={[styles.contentstext, {marginRight: perfectSize(10)},]}>NFT</Text>
                        </TouchableOpacity>
                    </View>
                }
                {item?.atmos?.content?.is_premium || item?.flac?.content?.is_premium || item?.vr360?.content?.is_premium || item?.dsd?.content?.is_premium ?
                    <View>
                        <View style={{flexDirection: 'row', marginTop: perfectSize(30), marginBottom: perfectSize(20)}}>
                            <Text style={[styles.payinfotext, {
                                marginRight: perfectSize(45)
                            }]}>모두 재생 & 다운로드하기</Text>
                            <Text style={styles.payinfotext}>{item?.price} 원</Text>
                        </View>
                    </View> : <View/>
                }
                <View style={{
                    backgroundColor: "#eeeeee",
                    height: perfectSize(3),
                    marginBottom: perfectSize(27),
                    marginTop: perfectSize(20)
                }}/>

                {/** 연주자 */}
                <Text style={styles.contenttext}>{"연주자"}</Text>
                <View
                    style={{
                        marginVertical: perfectSize(15),
                        maxHeight: isMorePlayer ? null : perfectSize(88),
                        overflow: "hidden",
                    }}
                >
                    <Text style={styles.contentinfotext}>{item?.player}</Text>
                </View>

                {/** 더보기 버튼 */}
                <TouchableOpacity
                    onPress={() => showMorePlayer(!isMorePlayer)}
                    style={styles.loadMoreBtn}
                >
                    <Text style={globalStyle.regularGreyText}>{"더보기"}</Text>
                    <Image
                        source={require("../../../assets/images/ic_more_detail.png")}
                        style={[
                            styles.loadMoreBtnIcon,
                            {transform: [{rotate: !isMorePlayer ? "0deg" : "180deg"}]},
                        ]}
                    />
                </TouchableOpacity>
                <View style={{
                    backgroundColor: "#eeeeee",
                    height: perfectSize(3),
                    marginTop: perfectSize(13),
                    marginBottom: perfectSize(20)
                }}/>
                {/** 곡해설 */}
                <Text style={styles.contenttext}>{"곡해설"}</Text>
                <View
                    style={{
                        marginVertical: perfectSize(15),
                        maxHeight: isMoreMusic ? null : perfectSize(88),
                        overflow: "hidden",
                    }}
                >
                    <Text style={styles.contentinfotext}>{item.description}</Text>
                </View>

                {/** 더보기 버튼 */}
                <TouchableOpacity
                    onPress={() => showMoreMusic(!isMoreMusic)}
                    style={[styles.loadMoreBtn, {marginBottom: perfectSize(30)}]}
                >
                    <Text style={globalStyle.regularGreyText}>{"더보기"}</Text>
                    <Image
                        source={require("../../../assets/images/ic_more_detail.png")}
                        style={[
                            styles.loadMoreBtnIcon,
                            {transform: [{rotate: !isMoreMusic ? "0deg" : "180deg"}]},
                        ]}
                    />
                </TouchableOpacity>
            </ScrollView>
            {
                (item.atmos?.content?.is_premium || item.flac?.content?.is_premium || item.dsd64?.content?.is_premium
                    || item.dsd?.content?.is_premium || item.vr360?.content?.is_premium) ?
                    isBuyContents ?
                        <View/> :
                        <View
                            style={[
                                globalStyle.flexRow,
                                {
                                    height: perfectSize(65),
                                    marginBottom: perfectSize(40),
                                },
                            ]}
                        >
                            <View style={{flex: 1, marginLeft: perfectSize(5), marginRight: perfectSize(15)}}>
                                <TouchableOpacity
                                    onPress={() => {
                                        navigation.navigate("Mania_benefits");
                                    }}
                                    style={
                                        {
                                            height: perfectSize(50),
                                            width: perfectSize(170),
                                            borderRadius: 12,
                                            borderWidth: 2,
                                            borderColor: c_primary_red,
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }
                                    }
                                >
                                    <Text style={{
                                        fontSize: perfectSize(15),
                                        color: c_primary_red,
                                        letterSpacing: -0.18,
                                        fontWeight: 'bold',
                                    }}
                                    >구독하기</Text>
                                    <Text style={{
                                        fontSize: perfectSize(10),
                                        color: c_primary_red,
                                        letterSpacing: -0.18,
                                        paddingTop: perfectSize(1),
                                    }}>월 5만원으로 콘텐츠 무제한 이용</Text>

                                </TouchableOpacity>
                            </View>
                            <View style={{flex: 1, marginLeft: perfectSize(5), marginRight: perfectSize(15)}}>
                                <TouchableOpacity
                                    onPress={Payment}
                                    style={
                                        {
                                            height: perfectSize(50),
                                            width: perfectSize(170),
                                            borderRadius: 12,
                                            borderWidth: 2,
                                            backgroundColor: c_primary_red,
                                            borderColor: c_primary_red,
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }
                                    }
                                >
                                    <Text style={{
                                        fontSize: perfectSize(15),
                                        color: c_white,
                                        letterSpacing: -0.18,
                                        fontWeight: 'bold',
                                    }}
                                    >결제하기</Text>
                                    <Text style={{
                                        fontSize: perfectSize(10),
                                        color: c_white,
                                        letterSpacing: -0.18,
                                        paddingTop: perfectSize(1),
                                    }}>{`해당 곡만 구매 ${isPrice} 원`}</Text>

                                </TouchableOpacity>
                            </View>
                            {/*<View style={{flex: 1, marginLeft: perfectSize(5), marginRight: perfectSize(15)}}>*/}
                            {/*    <Button*/}
                            {/*        onPress={Payment}*/}
                            {/*        isBorder={true}*/}
                            {/*        textColor={c_white}*/}
                            {/*        label={"결제하기"}*/}
                            {/*        color={c_primary_red}*/}
                            {/*    />*/}
                            {/*</View>*/}
                        </View> : <View/>

            }
            {visibleCardModal &&
                <CardModal
                    isVisible={visibleCardModal}
                    hideModal={onHideModal}
                    onSuccess={onSuccess}
                    moid={`ttticket0m${currentTimeRef.current}`}
                    data={`https://admin.tickettong.net/card-pay?product_name=${encodeURIComponent(item.title)}&price=${isPrice}&buyer_name=${encodeURIComponent(user?.nickname || user?.decPhoneNum)}&buyer_phone=${user?.decPhoneNum}&buyer_email=${email}&moid=ttticket0m${currentTimeRef.current}`}
                />
            }

        </View>
    );

}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#ffffff",
        paddingLeft: perfectSize(15),
        paddingRight: perfectSize(15)
    },
    runtimetext: {
        fontSize: perfectSize(15),
        letterSpacing: -0.1,
        color: c_primary_black,
        textAlign: 'center',
    },
    contentstitletext: {
        fontSize: perfectSize(14),
        letterSpacing: -0.01,
        color: c_primary_black,
        fontWeight: 'bold',
    },
    contentstext: {
        fontSize: perfectSize(11),
        letterSpacing: -0.01,
        color: c_primary_red,
        fontWeight: 'bold',

        borderColor: c_primary_red,
        borderWidth: 1,
        borderRadius: perfectSize(5),
        paddingTop: perfectSize(4),
        paddingBottom: perfectSize(4),
        paddingRight: perfectSize(6),
        paddingLeft: perfectSize(6),
        alignSelf: 'flex-start'

    },
    contentimage: {
        width: perfectSize(18),
        height: perfectSize(18),
        marginRight: perfectSize(20),
    },
    payinfotext: {
        fontSize: perfectSize(14),
        letterSpacing: -0.01,
        color: c_primary_red,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    loadMoreBtn: {
        flexDirection: "row",
        alignSelf: "stretch",
        alignItems: "center",
        justifyContent: "center",
        height: perfectSize(40),
        backgroundColor: "white",
    },
    loadMoreBtnIcon: {
        width: perfectSize(12),
        height: perfectSize(7),
        resizeMode: "contain",
        marginLeft: perfectSize(8),
    },
    contenttext: {
        fontSize: perfectSize(14),
        letterSpacing: -0.01,
        color: c_primary_black,
    },
    contentinfotext: {
        fontSize: perfectSize(12),
        letterSpacing: -0.01,
        color: "#999999",
    },
});