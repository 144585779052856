import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
} from 'react-native';
import {
    c_primary_red,
    c_second_grey,
    c_third_grey,
    c_white,
    c_black,
    c_primary_black
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import BorderButton from '../../components/BorderButton';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import RadioButton from '../../components/RadioButton';
import { setDefaultShippingAddress } from '../../store/actions';
import Button from "../../components/Button";

function ShippingAddress({ navigation, user, setDefaultShippingAddress }) {

    let [isSelect, setSelect] = useState(false);

    useEffect(() => {
        navigation.setOptions({
            title: "배송지 관리",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);

    const onSetDefault = async (item, idx) => {
        let deliveryAddress = user.delivery_address;
        deliveryAddress.map((item, index) => {
            if (idx === index) {
                setSelect = true;
            } else {
                setSelect = false;
            }
        });
        await setDefaultShippingAddress(deliveryAddress);
    }

    const onJoin = () => {
        navigation.navigate("BenefitsPage", {user: user});
    };

    return (
        <View style={styles.mainContainer}>
            <KeyboardAwareScrollView>
                <View style={styles.mainContent}>
                    <BorderButton
                        onPress={() => navigation.navigate('AddShippingAddress', { type: true, user: user })}
                        color={c_primary_red}
                        label={'+ 배송지 추가'}
                        buttonStyle={{ marginTop: perfectSize(0), marginBottom: perfectSize(30) }}
                    />
                    <View>
                        {
                            user.delivery_address &&
                            user.delivery_address.map((item, index) => {
                                return (
                                    <View style={styles.card} key={index}>
                                        <View style={globalStyle.flexSpaceBetween}>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    onSetDefault(item, index);
                                                    navigation.goBack();
                                                }}
                                                style={globalStyle.flexRow}>
                                                <RadioButton isSelected={isSelect ? true : false} onSelect={() => onSetDefault(item, index)} />
                                                <Text style={styles.name}>
                                                    {item.receiver}
                                                </Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity
                                                onPress={() => navigation.navigate('AddShippingAddress', { type: false, data: item, user: user })} >
                                                <Text style={styles.editButton}>
                                                    {'수정'}
                                                </Text>
                                            </TouchableOpacity>
                                        </View>

                                        <View style={[globalStyle.flexRow, { marginTop: perfectSize(22) }]}>
                                            <Text style={globalStyle.regularBoldText}>
                                                {item.receiver}
                                            </Text>
                                            <View style={styles.separator} />
                                            <Text style={globalStyle.regularBoldText}>
                                                {item.phoneNum}
                                            </Text>
                                        </View>

                                        <Text style={styles.idNumber}>
                                            {`[${item.post_code}]`}
                                        </Text>
                                        <Text style={globalStyle.smText}>
                                            {`${item.address1}\n${item.address2}`}
                                        </Text>
                                    </View>
                                )
                            })
                        }
                    </View>
                </View>
            </KeyboardAwareScrollView>
            {user && user?.subscribe[0]?.type === "MANIA" ?
                <View
                    style={[
                        globalStyle.flexRow,
                        {
                            height: perfectSize(65),
                        },
                    ]}
                >
                    <View style={{flex: 1, marginLeft: perfectSize(5), marginRight: perfectSize(15)}}>
                        <Button
                            onPress={onJoin}
                            isBorder={true}
                            textColor={c_white}
                            label={"혜택 보기"}
                            color={c_primary_red}
                        />
                    </View>
                </View> : <View />
            }
        </View >
    )
}

const mapStateTopProps = state => ({
    user: state.auth.user
});

export default connect(mapStateTopProps, { setDefaultShippingAddress })(ShippingAddress);

const styles = {
    mainContainer: {
        flex: 1,
        backgroundColor: c_white,
    },
    mainContent: {
        flex: 1,
        padding: perfectSize(15)
    },
    card: {
        borderWidth: 1,
        borderColor: c_third_grey,
        borderRadius: perfectSize(6),
        padding: perfectSize(20),
        marginBottom: perfectSize(10)
    },
    name: {
        letterSpacing: -0.15,
        fontWeight: 'bold',
        color: c_black,
        fontSize: perfectSize(16)
    },
    editButton: {
        letterSpacing: -0.15,
        color: c_primary_black,
        fontSize: perfectSize(13)
    },
    separator: {
        width: 1,
        height: perfectSize(12),
        backgroundColor: c_second_grey,
        marginHorizontal: perfectSize(8)
    },
    idNumber: {
        fontSize: perfectSize(12),
        letterSpacing: -0.15,
        color: c_black,
        marginTop: perfectSize(10),
        marginBottom: perfectSize(5)
    },
}