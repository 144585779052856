import React, {useState} from "react";
import {useSelector} from "react-redux";
import {View, ScrollView, Platform, Text} from "react-native";
import {SafeAreaView} from "react-native-safe-area-context";
import {c_white, c_primary_red} from "../../variables/colors";
import {globalStyle, perfectSize} from "../../variables/styles";
import Footer from "../../components/Footer";

import {VideoList, VR360List} from "./video";
import {DsdList, FLACList} from "./audio";
import {NFTList} from "./nft";
import {ReviewList} from "./review";
import Header from "./Header";
import BannerList from "./BannerList";
import IconList from "./IconList";
import AppInduction from "../../components/AppInduction"
import PopupInduction from "../../components/PopupInduction";
import {ContentList} from "./ContentList";

function Main(props) {
    const sUsrAg = navigator.userAgent;


    const {app} = useSelector(state => state);
    const {banners} = app;
    const {navigation} = props;

    const [toggleBar, setToggleBar] = useState(false);
    const [scroll, setScroll] = React.useState(0);

    const onScroll = e => {
        if (
            e.nativeEvent.contentOffset.y > 0 &&
            e.nativeEvent.contentOffset.y < e.nativeEvent.contentSize.height - 700
        ) {
            setToggleBar(e.nativeEvent.contentOffset.y > scroll);
            setScroll(e.nativeEvent.contentOffset.y);
        }
    };

    return (
        Platform.OS === 'web' ? !sUsrAg.match(/Windows/i) ?
                <View style={styles.mainContainer}>
                    <SafeAreaView style={globalStyle.flex}>
                        {/*<AppInduction/>*/}
                        <Header navigation={navigation}/>
                        <ScrollView scrollEventThrottle={16} onScroll={onScroll}>
                            <BannerList banners={banners}/>
                            <View style={styles.mainContent}>
                                <View style={{marginTop: perfectSize(50)}}/>
                                <PopupInduction navigation={navigation}/>
                                <ContentList banners={banners}/>
                                {/*<Footer/>*/}
                            </View>
                            {/*<View style={styles.mainContent}>*/}
                            {/*    <View style={{marginTop: perfectSize(80)}}>*/}
                            {/*        <PopupInduction/>*/}
                            {/*        <VideoList/>*/}
                            {/*    </View>*/}
                            {/*    <VR360List/>*/}
                            {/*    <FLACList/>*/}
                            {/*    <DsdList/>*/}
                            {/*    <NFTList/>*/}
                            {/*    <ReviewList/>*/}
                            {/*    <Footer/>*/}
                            {/*</View>*/}
                        </ScrollView>
                        {/*<IconList toggleBar={toggleBar}/>*/}
                    </SafeAreaView>
                </View>
                :
                <View style={styles.mainContainer}>
                    <SafeAreaView style={globalStyle.flex}>
                        <Header navigation={navigation}/>
                        <ScrollView scrollEventThrottle={16} onScroll={onScroll}>
                            <BannerList banners={banners}/>
                            <View style={styles.mainContent}>
                                <View style={{marginTop: perfectSize(30)}}/>
                                <ContentList banners={banners}/>
                                {/*<Footer/>*/}
                            </View>
                        </ScrollView>
                        {/*<IconList toggleBar={toggleBar}/>*/}
                    </SafeAreaView>
                </View> :
            <View/>
    );
}

export default Main;

const styles = {
    mainContainer: {
        flex: 1,
        backgroundColor: c_primary_red,
    },
    mainContent: {
        flex: 1,
        backgroundColor: c_white,
        paddingHorizontal: perfectSize(15),
        marginTop: perfectSize(160),
        minHeight: perfectSize(400),
    },
};
