import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import {
    ActivityIndicator,
    Dimensions,
    View,
    Text,
    Image,
    TouchableOpacity,
    ScrollView,
    TextInput
} from 'react-native';
import {
    c_third_grey,
    c_white,
    c_primary_red
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import Separator from '../../components/Separator';
import moment from 'moment';
import HTML from 'react-native-render-html';
import { getNoticeDetail } from '../../store/actions';

const { width } = Dimensions.get('window');

export default function NoticeDetail({
    navigation,
    route
}) {

    const [loading, setLoading] = useState(false);
    const [notice, setNotice] = useState();
    const { id } = route.params;

    useEffect(() => {
        navigation.setOptions({
            title: "공지사항",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before_stroke.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.navigate('Home')}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
        getNoticeDetails();
    }, []);

    const getNoticeDetails = async () => {
        setLoading(true);
        const res = await getNoticeDetail(id);
        setNotice(res);
        setLoading(false);
    }

    const tagsStyles = {
        p: {
            marginTop: 0,
            marginBottom: 0,
        },
    };

    if (loading) return (
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            <ActivityIndicator color={c_primary_red} />
        </View>
    );
    if (_.isEmpty(notice)) {
        return (
            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: perfectSize(20) }}>
                <Text>{'공지사항이 없습니다.'}</Text>
            </View>
        )
    }

    return (
        <View style={{ flex: 1, backgroundColor: c_white }}>
            <ScrollView>
                <View style={styles.mainContainer}>
                    <Text style={globalStyle.bigBoldText}>
                        {notice.name}
                    </Text>
                    <Text style={[globalStyle.smGreyText, { marginTop: perfectSize(10) }]}>
                        {moment(notice.updatedAt).format('YYYY.MM.DD')}
                    </Text>
                    <Separator
                        height={1}
                        color={c_third_grey}
                        marginTop={perfectSize(15)}
                        marginBottom={perfectSize(20)}
                    />
                    <HTML
                        source={{ html: notice.content }}
                        baseFontStyle={globalStyle.regularText}
                        tagsStyles={tagsStyles}
                        containerStyle={styles.htmlContainerStyle}
                        contentWidth={width}
                    />
                </View>
            </ScrollView>
        </View>
    )
}

const styles = {
    mainContainer: {
        flex: 1,
        paddingHorizontal: perfectSize(15),
        paddingTop: perfectSize(25),
    },
    htmlContainerStyle: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    }
}