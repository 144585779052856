import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {
    Dimensions,
    View,
    Image,
    TouchableOpacity,
    Text,
    ScrollView, Platform, Linking
} from 'react-native';
import {globalStyle, perfectSize} from '../../variables/styles';
import Button from "../../components/Button";
import {
    c_fourth_white,
    c_primary_black,
    c_primary_red,
    c_third_grey,
    c_white
} from "../../variables/colors";
import {numberWithCommas, oneAlert, twoAlert} from "../../utils";
import {connect} from "react-redux";
import {getRewardPoint} from "../../store/actions";

function Mania_benefits({
                            isAuthenticated,
                            user,
                            navigation
                        }) {

    const sUsrAg = navigator.userAgent;
    const [rewardPoint, setRewardPoint] = useState(0);

    useEffect(() => {
        navigation.setOptions({
            title: '티켓통 매니아 회원 혜택',
            // headerLeft: () => (
            //     <View style={globalStyle.flexRow}>
            //         <TouchableOpacity onPress={() => {
            //             if (isAuthenticated) {
            //                 navigation.navigate('Home');
            //             } else {
            //                 navigation.goBack();
            //             }
            //         }}>
            //             <Image
            //                 source={require('../../../assets/images/ic_before_stroke.png')}
            //                 style={globalStyle.headerLeftBack}
            //             />
            //         </TouchableOpacity>
            //     </View>
            // )
        });
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            getRewardData();
        }
    }, [user]);

    const getRewardData = async () => { // user.decPhoneNum 01050556371
        await getRewardPoint(user?._id).then(res => {
            if (res && res.Code === "200") {
                const point = parseInt(res.data.Point);
                setRewardPoint(point);
            }
        });
    }

    const onJoin = () => {
        if (isAuthenticated) {
            navigation.navigate('Terms', {type: 5, user: user, point: rewardPoint});
        } else {
            navigation.navigate("SignIn", {screenName: 'Mania_benefits'});
        }
    };

    const onDownload = () => {
        if (!sUsrAg.includes('Mobile')) {
            oneAlert('티켓통 App에서 이용해주세요')
        } else {
            if (Platform.OS === "android") {
                Linking.openURL(
                    `https://play.google.com/store/apps/details?id=com.tongtong.wallet`
                );
            }
            if (Platform.OS === "ios") {
                Linking.openURL(
                    `https://apps.apple.com/kr/app/id1618695778`
                );
            }
        }
    }

    return (
        <View style={globalStyle.mainContainer}>
            <ScrollView showsVerticalScrollIndicator={false}>
                <View style={styles.scrollContainer}>
                    <View style={{
                        marginTop: perfectSize(15),
                        marginLeft: perfectSize(15),
                        marginRight: perfectSize(15),
                        backgroundColor: c_fourth_white
                    }
                    }>
                        <View>
                            <Text style={styles.headText}>1. 토마토홀 기획공연 초청</Text>
                            <Text style={styles.contentText}>매달 토마토홀에서 열리는 기획공연을</Text>
                            <Text style={styles.contentText}>무료로 관람하실 수 있습니다.</Text>
                            <Text style={styles.contentText}>* 매달 1회 초대권 1매 발송</Text>
                        </View>

                        <View>
                            <Text style={styles.headText}>2. 제휴 공연 70% 할인</Text>
                            <Text style={styles.contentText}>토마토클래식 기획 공연과 제휴 공연을 </Text>
                            <Text style={styles.contentText}>최대 70%할인하여 예매할 수 있습니다.</Text>
                            <Text style={styles.contentText}>TTC로 결제하시면 잔액 5% 추가 할인까지!</Text>
                            <Text style={styles.contentInfoText}>* 전체 약정금액 한도 내 할인 제공</Text>
                        </View>

                        <View>
                            <Text style={styles.headText}>3. 티켓통 콘텐츠 무제한 이용</Text>
                            <Text style={styles.contentText}>티켓통의 모든 4K Atmos, VR360, FLAC, DSD </Text>
                            <Text style={styles.contentText}>콘텐츠를 자유롭게 이용하실 수 있습니다.</Text>
                        </View>

                        <View>
                            <Text style={styles.headText}>4. 토마토 그룹사 서비스 최대 70% 할인</Text>
                            <Text style={styles.contentText}>토마토그룹 계열사 정기구독 및 유료 상품</Text>
                            <Text style={styles.contentText}>이용시 최대 70%까지 할인됩니다.</Text>
                            <Text style={styles.contentText}>(단, 통통몰 쇼핑시 최대 30%까지 할인)</Text>
                            <Text style={styles.contentText}>그리고 TTC로 결제하시면 잔액5% 추가 할인!</Text>
                            <Text style={styles.contentInfoText}>* 전체 약정금액 한도 내 할인 제공</Text>
                        </View>

                        <View>
                            <Text style={styles.headText}>5. 가입선물 증정</Text>
                            <Text style={styles.contentText}>최초 가입 시 다양한 감사 선물을 드립니다.</Text>
                            <TouchableOpacity onPress={() => Linking.openURL("https://tongtonglife.com/")}>
                                <Text style={styles.contentClickText}>통통생활 보기</Text>
                            </TouchableOpacity>
                        </View>

                        <View>
                            <Text style={styles.headText}>6. 가상자산'통통코인 TTCOIN'지급</Text>
                            <Text style={styles.contentText}>전체 약정금액의 5%에 해당하는 금액을</Text>
                            <Text style={styles.contentText}>'통통코인(TTC)'으로 돌려드립니다.</Text>
                            <Text style={styles.contentText}>TTC는 토마토그룹 전 서비스에서 현금처럼 사용하실 수 있습니다.</Text>
                        </View>

                        <View>
                            <Text style={[styles.headText, {color: c_primary_red}]}>※ 이미 토마토 그룹사 구독중인 경우,</Text>
                            <Text style={[styles.contentText, {color: c_primary_red}]}>보유하신TTCO로 구독 금액의 70%까지 할인 받으실 수 있습니다.</Text>
                            <Text style={[styles.contentText, {color: c_primary_red}]}>할인 구독자의 경우, 사은품 증정 대상에서 제외됩니다.</Text>
                            <View style={{flexDirection: 'row'}}>
                                <Text style={[styles.contentText,{textDecorationLine: 'line-through', color: c_primary_red}]}>월 5만원</Text><Text style={[styles.contentText, {color: c_primary_red, marginLeft: perfectSize(10), fontWeight: 'bold'}]}>→ 월 1만 5천원</Text>
                            </View>
                            <Text style={styles.contentText}>{
                                `보유중이신 TTCO : ` + `${numberWithCommas(
                                Number(rewardPoint)
                                )}`
                            }</Text>
                        </View>


                        <View>
                            <Image
                                style={styles.contentImage}
                                source={require('../../../assets/images/mania_benefits_info.png')}
                            />
                            <Text style={[styles.contentlastText, {marginTop: perfectSize(20)}]}>티켓통 매니아회원이 되어</Text>
                            <Text style={styles.contentlastText}>이 모든 혜택을 누려보세요!</Text>
                        </View>

                    </View>
                </View>
            </ScrollView>
            {Platform.OS === 'web' ? !sUsrAg.match(/Windows/i) ?
                    <View
                        style={[
                            globalStyle.flexRow,
                            {
                                height: perfectSize(65),
                                marginBottom: perfectSize(50),
                            },
                        ]}
                    >
                        <View style={{flex: 1, marginLeft: perfectSize(15), marginRight: perfectSize(15)}}>
                            <Button
                                onPress={onJoin}
                                isBorder={true}
                                textColor={c_white}
                                label={"티켓통 매니아 구독하기"}
                                color={c_primary_red}
                            />
                        </View>
                    </View> :
                    <View
                        style={[
                            globalStyle.flexRow,
                            {
                                height: perfectSize(65),
                                marginBottom: perfectSize(15),
                            },
                        ]}
                    >
                        <View style={{flex: 1, marginLeft: perfectSize(15), marginRight: perfectSize(15)}}>
                            <Button
                                onPress={onJoin}
                                isBorder={true}
                                textColor={c_white}
                                label={"티켓통 매니아 구독하기"}
                                color={c_primary_red}
                            />
                        </View>
                    </View> :
                <View/>
            }
        </View>
    )
}

const mapStateTopProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
});

export default connect(mapStateTopProps)(Mania_benefits);

const styles = {
    scrollContainer: {
        padding: perfectSize(15)
    },
    headText: {
        fontSize: perfectSize(16),
        letterSpacing: -0.16,
        color: "#222222",
        marginTop: perfectSize(36),
        marginLeft: perfectSize(15),
        marginBottom: perfectSize(6)
    },
    contentText: {
        fontSize: perfectSize(15),
        letterSpacing: -0.15,
        color: "#222222",
        marginLeft: perfectSize(40),
    },
    contentInfoText: {
        fontSize: perfectSize(11),
        letterSpacing: -0.11,
        color: "#777777",
        marginLeft: perfectSize(35),
    },
    contentClickText: {
        fontSize: perfectSize(11),
        letterSpacing: -0.11,
        color: "#1875CA",
        marginLeft: perfectSize(40),
        textDecorationLine: "underline",
    },
    contentlastText: {
        fontSize: perfectSize(14),
        letterSpacing: -0.14,
        color: "#222222",
        textAlign: 'center',

    },
    contentImage: {
        width: perfectSize(350),
        height: perfectSize(100),
        marginTop: perfectSize(30)
    }
}